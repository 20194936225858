import styled from "styled-components";
import { Spacing } from "@find-truck-service/types/ui/spacing";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
export const FtsAccordionSummary = styled.button.withConfig({
    shouldForwardProp: (prop) => !["show"].includes(prop),
}) `
  border-radius: ${(props) => (props.show ? `${Spacing["2xs"]} ${Spacing["2xs"]} 0 0` : `${Spacing["2xs"]}`)};
  background-color: ${ColorsValue["greyscale-0"]};
  transition: border-radius 0.3s ease;
  justify-content: space-between;
  padding: ${Spacing.lg};
  align-items: center;
  gap: ${Spacing.md};
  cursor: pointer;
  display: flex;
  border: none;
  width: 100%;

  svg {
    width: 24px;
    height: 24px;
    path {
      stroke: ${ColorsValue["greyscale-500"]};
    }
  }

  &:hover {
    outline: ${Spacing["4xs"]} solid ${ColorsValue["blue-accent-300"]};
  }

  &:focus,
  &:focus-visible {
    outline: ${Spacing["3xs"]} solid ${ColorsValue["blue-accent-300"]};
  }

  &:disabled {
    pointer-events: none;
    cursor: default;
    span {
      color: ${ColorsValue["greyscale-300"]};
    }
    path {
      stroke: ${ColorsValue["greyscale-300"]};
    }
  }
`;
