export var ZIndexValues;
(function (ZIndexValues) {
    ZIndexValues[ZIndexValues["HEADER"] = 48] = "HEADER";
    ZIndexValues[ZIndexValues["SIDE_OUT_WRAPPER"] = 49] = "SIDE_OUT_WRAPPER";
    ZIndexValues[ZIndexValues["DRAWER"] = 50] = "DRAWER";
    ZIndexValues[ZIndexValues["LOADER_WRAPPER"] = 50] = "LOADER_WRAPPER";
    ZIndexValues[ZIndexValues["MODAL_WRAPPER"] = 50] = "MODAL_WRAPPER";
    ZIndexValues[ZIndexValues["LOADER"] = 51] = "LOADER";
    ZIndexValues[ZIndexValues["SIDE_OUT_WRAPPER_FOCUSSED"] = 1034] = "SIDE_OUT_WRAPPER_FOCUSSED";
    ZIndexValues[ZIndexValues["SIDE_OUT_CONTAINER_FOCUSSED"] = 1035] = "SIDE_OUT_CONTAINER_FOCUSSED";
    ZIndexValues[ZIndexValues["SIDE_OUT_WRAPPER_UN_FOCUSSED"] = 1033] = "SIDE_OUT_WRAPPER_UN_FOCUSSED";
    ZIndexValues[ZIndexValues["SIDE_OUT_CONTAINER_UN_FOCUSSED"] = 1034] = "SIDE_OUT_CONTAINER_UN_FOCUSSED";
})(ZIndexValues || (ZIndexValues = {}));
