import React from "react";
import { FtsWrapper } from "../../../../FtsWrapper/FtsWrapper";
import { P } from "../../../../FtsText";
import { ButtonColorsValue, ButtonSizes, ButtonTypes } from "@find-truck-service/types/ui/button";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { VariantsValues } from "@find-truck-service/types/ui/text";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
export const SideOutErrorState = (props) => {
    return (React.createElement(FtsWrapper, { py: "2xl", px: "3xl", display: "flex", align: "center", justify: "center", width: "100%", height: "100%" },
        React.createElement(FtsWrapper, null,
            React.createElement(P, { variant: VariantsValues["text-md"], color: ColorsValue["greyscale-800"] }, props.error || "Cannot find listing data!"),
            React.createElement(Button, { type: ButtonTypes.outlined, color: ButtonColorsValue.accent, size: ButtonSizes.sm, onClick: props.onClick, label: "Re-fetch" }))));
};
