import styled from "styled-components";
import { defaultStyleProps } from "../default";
import { FtsWrapper } from "../FtsWrapper/FtsWrapper";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { Spacing } from "@find-truck-service/types/ui/spacing";
export const FtsAccordionContent = styled(FtsWrapper) `
  ${defaultStyleProps};
  overflow: hidden;
  transition: max-height 0.3s ease;
  background-color: ${ColorsValue["greyscale-0"]};
  border-radius: 0 0 ${Spacing["2xs"]} ${Spacing["2xs"]};
  max-height: ${({ show }) => (show ? "500px" : "0")};
`;
