import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setAltContactAction,
  setAltContactInvalidFormFieldsAction,
} from "../../../../redux/actions/v2/altContacts";
import { AccountAltContactItems } from "./AccountAltContactItems";
import { AccountAltContactEmpty } from "./AccountAltContactEmpty";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import { AccountAltContactFormHandler } from "./AccountAltContactFormHandler";
import { selectAltContactsLength } from "../../../../redux/reducers/v2/altContacts";
import {
  ButtonColorsValue,
  ButtonSizes,
  ButtonTypes,
} from "@find-truck-service/types/ui/button";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";

export const AccountAltContactContent = () => {
  const dispatch = useDispatch();
  const altContactsSize = useSelector(selectAltContactsLength);
  const [showForm, setShowForm] = useState(false);
  const [hideAddButton, setHideAddButton] = useState(false);
  const showEmpty = !altContactsSize && !showForm;

  const openAltContactForm = useCallback(() => {
    dispatch(setAltContactInvalidFormFieldsAction(null));
    dispatch(setAltContactAction(null));
    setShowForm(true);
    setHideAddButton(true);
  }, []);

  const closeAltContactForm = useCallback(() => {
    setHideAddButton(false);
    setShowForm(false);
  }, []);

  return (
    <>
      <AccountAltContactItems
        showForm={showForm}
        setHideAddButton={setHideAddButton}
      />
      <AccountAltContactEmpty show={showEmpty} />
      {showForm && (
        <AccountAltContactFormHandler
          title={"New alt contact"}
          submitButtonTitle={"Add Contact"}
          closeAltContactForm={closeAltContactForm}
        />
      )}
      {!hideAddButton && (
        <FtsColumn size={{ xs: 12 }}>
          <Button
            onClick={openAltContactForm}
            type={ButtonTypes.outlined}
            color={ButtonColorsValue.accent}
            size={ButtonSizes.sm}
            iconName={IconNames.Plus}
            label="Add Contact"
          />
        </FtsColumn>
      )}
    </>
  );
};
