import React, { useCallback } from "react";
import { FtsColumn } from "../../dist/ui/src/FtsColumn/FtsColumn";
import { Span } from "../../dist/ui/src/FtsText";
import { FtsRow } from "../../dist/ui/src/FtsRow/FtsRow";
import Checkbox from "../../dist/ui/src/components/react/Checkbox";
import { Input } from "../../dist/ui/src/components/react/Input";
import { InputSizes, InputVariants } from "../../dist/types/ui/input";
import { ToggleSwitch } from "../../dist/ui/src/components/react/Toggle";
import { useDispatch, useSelector } from "react-redux";
import { selectListingData } from "../../../../redux/reducers/v2/listing";
import { getListingDataByIdSuccess } from "../../../../redux/actions/v2/listings";
import { Dropdown } from "../../dist/ui/src/components/react/Dropdown";
import {
  hoursListFrom,
  hoursListTo,
} from "../../../fts/formik/hour-input/options";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { ColorsValue } from "@find-truck-service/types/ui/colors";

export const weekDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
export const dontShowWorkingHoursToDropdown = [
  "24 hrs",
  "By Appointment",
  "Closed",
  "closed",
];
export const ListingWorkingHours = () => {
  const listingData = useSelector(selectListingData);
  const dispatch = useDispatch();

  const onChange = useCallback(
    (key, val) => {
      dispatch(
        getListingDataByIdSuccess({ data: { ...listingData, [key]: val } }),
      );
    },
    [listingData],
  );
  const handleChangeWorkingHours = useCallback(
    (key, val) => {
      dispatch(
        getListingDataByIdSuccess({
          data: {
            ...listingData,
            workingHours: { ...listingData.workingHours, [key]: val },
          },
        }),
      );
    },
    [listingData],
  );
  const handleChangeSkipWorkingHours = useCallback(() => {
    dispatch(
      getListingDataByIdSuccess({
        data: {
          ...listingData,
          skipHours: !listingData.skipHours,
          workingHours: {
            ...listingData.workingHours,
          },
        },
      }),
    );
  }, [listingData]);

  return (
    <FtsRow columnGap={"lg"} rowGap={"lg"} mt={"2xl"}>
      <FtsColumn size={{ xs: 6 }}>
        <FtsRow columnGap={"lg"} rowGap={"lg"}>
          <FtsColumn size={{ xs: 12 }}>
            <Span
              weight={WeightSize.medium}
              variant={VariantsValues["text-3xs"]}
              color={ColorsValue["blue-accent-500"]}
            >
              WORK HOURS
            </Span>
          </FtsColumn>
          <FtsColumn size={{ xs: 12 }}>
            <ToggleSwitch
              onClick={() => onChange("alwaysOpen", !listingData.alwaysOpen)}
              checked={listingData.alwaysOpen}
              label={"Are you open 24 hours?"}
            />
          </FtsColumn>
          <FtsColumn size={{ xs: 12 }}>
            {!listingData.alwaysOpen && (
              <ToggleSwitch
                onClick={handleChangeSkipWorkingHours}
                checked={listingData.skipHours}
                label={"Skip working hours"}
              />
            )}
          </FtsColumn>
          <FtsColumn size={{ xs: 12 }}>
            {!listingData.alwaysOpen && !listingData.skipHours
              ? weekDays.map((e, i) => {
                  return (
                    <FtsRow key={i} columnGap={"lg"} rowGap={"lg"} mt={"lg"}>
                      <FtsColumn size={{ xs: 2 }}>{e}</FtsColumn>
                      <FtsColumn size={{ xs: 5 }}>
                        <Dropdown
                          size={"sm"}
                          options={hoursListFrom}
                          value={
                            listingData.workingHours[`${e.toLowerCase()}From`]
                          }
                          onChange={(val) =>
                            handleChangeWorkingHours(
                              `${e.toLowerCase()}From`,
                              val,
                            )
                          }
                        />
                      </FtsColumn>
                      {dontShowWorkingHoursToDropdown.indexOf(
                        listingData.workingHours[`${e.toLowerCase()}From`]
                          ?.label,
                      ) === -1 ? (
                        <FtsColumn size={{ xs: 5 }}>
                          <Dropdown
                            size={"sm"}
                            options={hoursListTo}
                            value={
                              listingData.workingHours[`${e.toLowerCase()}To`]
                            }
                            onChange={(val) =>
                              handleChangeWorkingHours(
                                `${e.toLowerCase()}To`,
                                val,
                              )
                            }

                            // name={"state"}
                            // value={states.find((e) => e.value === listingData.state)}
                            // onChange={(val) =>
                            //   onChange({
                            //     target: { name: "state", value: val?.label || "" },
                            //   })
                            // }
                          />
                        </FtsColumn>
                      ) : null}
                    </FtsRow>
                  );
                })
              : null}
          </FtsColumn>
        </FtsRow>
      </FtsColumn>
      <FtsColumn size={{ xs: 6 }}>
        <FtsColumn size={{ xs: 6 }} mb={"lg"}>
          <Span
            weight={WeightSize.medium}
            variant={VariantsValues["text-3xs"]}
            color={ColorsValue["blue-accent-500"]}
          >
            SPECIAL HOURS
          </Span>
        </FtsColumn>
        <FtsRow columnGap={"lg"} rowGap={"lg"}>
          <FtsColumn size={{ xs: 12 }}>
            <Checkbox
              onClick={() =>
                onChange("towingRecoveryOpen", !listingData.towingRecoveryOpen)
              }
              checked={listingData.towingRecoveryOpen}
              label={"24 hour Towing & Recovery"}
            />
          </FtsColumn>
          <FtsColumn size={{ xs: 12 }}>
            <Checkbox
              onClick={() =>
                onChange(
                  "mobileRoadServiceOpen",
                  !listingData.mobileRoadServiceOpen,
                )
              }
              checked={listingData.mobileRoadServiceOpen}
              label={"24 hour Mobile/Road Service"}
            />
          </FtsColumn>
          <FtsColumn size={{ xs: 12 }}>
            <Input
              onChange={(e) => onChange("specialHours", e.target.value)}
              name={"specialHours"}
              value={listingData.specialHours}
              label={"Special hours"}
              variant={InputVariants.outlined}
              size={InputSizes.sm}
            />
          </FtsColumn>
        </FtsRow>
      </FtsColumn>
    </FtsRow>
  );
};
