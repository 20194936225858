import React from "react";
const Google = (props) => {
    return (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props.svgProps },
        React.createElement("g", { "clip-path": "url(#clip0_597_26352)" },
            React.createElement("path", { d: "M23.49 12.27C23.49 11.48 23.42 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.33 17.24 16.07 18.09V21.09H19.93C22.19 19 23.49 15.92 23.49 12.27Z", fill: "#4285F4", ...props.pathProps }),
            React.createElement("path", { d: "M12 24C15.24 24 17.95 22.92 19.93 21.09L16.07 18.09C14.99 18.81 13.62 19.25 12 19.25C8.87004 19.25 6.22004 17.14 5.27004 14.29H1.29004V17.38C3.26004 21.3 7.31004 24 12 24Z", fill: "#34A853", ...props.pathProps }),
            React.createElement("path", { d: "M5.27 14.29C5.02 13.57 4.89 12.8 4.89 12C4.89 11.2 5.03 10.43 5.27 9.71V6.62H1.29C0.469999 8.24 0 10.06 0 12C0 13.94 0.469999 15.76 1.29 17.38L5.27 14.29Z", fill: "#FBBC05", ...props.pathProps }),
            React.createElement("path", { d: "M12 4.75C13.77 4.75 15.35 5.36 16.6 6.55L20.02 3.13C17.95 1.19 15.24 0 12 0C7.31004 0 3.26004 2.7 1.29004 6.62L5.27004 9.71C6.22004 6.86 8.87004 4.75 12 4.75Z", fill: "#EA4335", ...props.pathProps })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_597_26352" },
                React.createElement("rect", { width: "24", height: "24", fill: "white", ...props.pathProps })))));
};
export default Google;
