import styled from "styled-components";
import { device } from "@find-truck-service/types/ui/breakpoints";
import { Spacing } from "@find-truck-service/types/ui/spacing";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
const rowProps = ["rowGap", "columnGap"];
export const FtsRow = styled(FtsWrapper).withConfig({
    shouldForwardProp: (prop) => !rowProps.includes(prop),
}) `
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: ${(props) => Spacing[props?.columnGap || "3xl"]};
  row-gap: ${(props) => Spacing[props?.rowGap || "4xl"]};
  @media ${device.md} {
    column-gap: ${Spacing["lg"]};
  }
  @media ${device.xs} {
    column-gap: ${Spacing["sm"]};
  }
`;
