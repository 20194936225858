import React from "react";
import { MessageButtonColor } from "../index";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { ButtonSizes, ButtonTypes } from "@find-truck-service/types/ui/button";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
export const MessageButtonsSm = (props) => {
    return (React.createElement(FtsWrapper, { gap: "lg", align: "center", direction: "column", display: { xs: "flex", sm: "none", md: "none", lg: "none" } },
        props.showConfirmButton && (React.createElement(Button, { width: "100%", size: ButtonSizes.md, onClick: props.onConfirm, type: ButtonTypes.contained, color: MessageButtonColor[props.type], label: props.confirmButtonLabel, iconName: IconNames.AlertOctagon })),
        props.showCancelButton && (React.createElement(Button, { width: "100%", size: ButtonSizes.md, onClick: props.onCancel, type: ButtonTypes.outlined, color: MessageButtonColor[props.type], label: props.cancelButtonLabel, iconName: IconNames.AlertOctagon }))));
};
