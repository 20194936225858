import React from "react";
import * as Icons from "./index";
import { EmptyComponent } from "../components/react/EmptyComponent";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
const FtsIcons = (props) => {
    if (!props.iconName)
        return React.createElement(EmptyComponent, null);
    const Component = Icons[props.iconName];
    if (!Component)
        return React.createElement(EmptyComponent, null);
    return (React.createElement(FtsWrapper, { display: "flex", ...props.iconWrapperProps },
        React.createElement(Component, { ...props, ...props.iconWrapperProps })));
};
export default FtsIcons;
