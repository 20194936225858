import React, { useCallback } from "react";
import { Span } from "../../dist/ui/src/FtsText";
import { useDispatch, useSelector } from "react-redux";
import { types } from "../../../fts/account-type-picker";
import { FtsRow } from "../../dist/ui/src/FtsRow/FtsRow";
import { Input } from "../../dist/ui/src/components/react/Input";
import { FtsColumn } from "../../dist/ui/src/FtsColumn/FtsColumn";
import { accountStatusOptions } from "../../../fts/activation-picker";
import { Dropdown } from "../../dist/ui/src/components/react/Dropdown";
import { setAccountDataAction } from "../../../../redux/actions/v2/account";
import { selectUserStatuses } from "../../../../redux/reducers/essentialLists";
import { InputSizes, InputVariants } from "../../dist/types/ui/input";
import {
  selectAccountData,
  selectAccountInvalidFormFields,
} from "../../../../redux/reducers/v2/account";
import isObject from "lodash/isObject";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { ColorsValue } from "@find-truck-service/types/ui/colors";

export const AccountDetailsRow = () => {
  const accountData = useSelector(selectAccountData);

  const userStatuses = useSelector(selectUserStatuses);
  const dispatch = useDispatch();
  const invalidFormFields = useSelector(selectAccountInvalidFormFields);

  const onChange = useCallback(
    (event) => {
      dispatch(
        setAccountDataAction({
          ...accountData,
          [event.target.name]: event.target.value,
        }),
      );
    },
    [accountData],
  );

  return (
    <FtsRow columnGap={"lg"} rowGap={"lg"} py={"2xl"}>
      <FtsColumn size={{ xs: 12 }}>
        <Span
          weight={WeightSize.medium}
          variant={VariantsValues["text-3xs"]}
          color={ColorsValue["blue-accent-500"]}
        >
          ACCOUNT
        </Span>
      </FtsColumn>
      <FtsColumn size={{ xs: 4 }}>
        <Input
          name={"firstName"}
          value={accountData.firstName}
          onChange={onChange}
          label={"First name"}
          required
          variant={InputVariants.outlined}
          size={InputSizes.sm}
          errorMessage={
            isObject(invalidFormFields) && invalidFormFields["firstName"]
          }
        />
      </FtsColumn>
      <FtsColumn size={{ xs: 4 }}>
        <Input
          name={"lastName"}
          value={accountData.lastName}
          onChange={onChange}
          label={"Last name"}
          required
          variant={InputVariants.outlined}
          size={InputSizes.sm}
          errorMessage={
            isObject(invalidFormFields) && invalidFormFields["lastName"]
          }
        />
      </FtsColumn>
      <FtsColumn size={{ xs: 4 }}>
        <Input
          name={"jobTitle"}
          value={accountData.jobTitle}
          onChange={onChange}
          label={"Title/Position"}
          variant={InputVariants.outlined}
          size={InputSizes.sm}
        />
      </FtsColumn>
      <FtsColumn size={{ xs: 3 }}>
        <Dropdown
          size={"sm"}
          errorMessage={
            isObject(invalidFormFields) && invalidFormFields["status"]
          }
          options={accountStatusOptions}
          label={"Status"}
          required
          name={"status"}
          value={accountStatusOptions.find(
            (e) => e.value === accountData.status,
          )}
          onChange={(val) =>
            onChange({ target: { name: "status", value: val?.value ?? 0 } })
          }
        />
      </FtsColumn>
      <FtsColumn size={{ xs: 3 }}>
        <Dropdown
          size={"sm"}
          errorMessage={
            isObject(invalidFormFields) && invalidFormFields["userType"]
          }
          options={types}
          label={"Account type"}
          required
          name={"userType"}
          value={types.find((e) => e.value === accountData.userType)}
          onChange={(val) =>
            onChange({ target: { name: "userType", value: val?.value } })
          }
        />
      </FtsColumn>
      <FtsColumn size={{ xs: 3 }}>
        <Dropdown
          size={"sm"}
          errorMessage={
            isObject(invalidFormFields) && invalidFormFields["adminStatus"]
          }
          options={userStatuses}
          label={"Account label"}
          required
          name={"adminStatus"}
          value={userStatuses.find((e) => e.value === accountData.adminStatus)}
          onChange={(val) =>
            onChange({
              target: { name: "adminStatus", value: val?.value },
            })
          }
        />
      </FtsColumn>
      <FtsColumn size={{ xs: 3 }}>
        <Input
          name={"adminMerchantId"}
          value={accountData.adminMerchantId}
          onChange={onChange}
          label={"Merchant ID"}
          variant={InputVariants.outlined}
          size={InputSizes.sm}
          errorMessage={
            isObject(invalidFormFields) && invalidFormFields["adminMerchantId"]
          }
        />
      </FtsColumn>
    </FtsRow>
  );
};
