import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider, useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";

import App from "./App";
import { IntlProviderWrapper } from "./utility/context/Internationalization";
import { Layout } from "./utility/context/Layout";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./redux/storeConfig/store";
import Spinner from "./components/@vuexy/spinner/Fallback-spinner";

import packageJson from "../package.json";
import "react-toastify/dist/ReactToastify.css";
import "./assets/scss/plugins/extensions/toastr.scss";
import "./index.scss";
import "@find-truck-service/ui/src/components/react/RichTextEditor/Styled/RichTextEditor.css";
import "@find-truck-service/ui/src/components/react/RichTextEditor/Styled/quill.snow.css";
import SideOut from "./components/fts-components-v2/dist/ui/src/components/react/SideOut";
import { history } from "./history";
import { ConnectedRouter } from "connected-react-router";
import {
  closeSideOutAction,
  closeSubSideOutAction,
} from "./redux/actions/v2/sideOut";
import {
  selectIsSideOutOpen,
  selectSideOutComponent,
  selectSubSideOutComponent,
} from "./redux/reducers/v2/sideOut";

const store = configureStore();
console.error("VERSION: ", packageJson.version);

const MainApp = () => {
  const dispatch = useDispatch();
  const SideOutComponent = useSelector(selectSideOutComponent);
  const SubSideOutComponent = useSelector(selectSubSideOutComponent);
  const isSideOutOpen = useSelector(selectIsSideOutOpen);
  const isSubSideOutOpen = useSelector(selectSubSideOutComponent);

  return (
    <Suspense fallback={<Spinner />}>
      <SideOut
        isOpen={isSideOutOpen}
        closeSideOutAction={() => dispatch(closeSideOutAction())}
        sideOutContainerProps={{
          width: isSubSideOutOpen ? "1250px" : "",
        }}
      >
        {SideOutComponent && <SideOutComponent />}
      </SideOut>
      <SideOut
        isInFocus={isSubSideOutOpen}
        isOpen={isSubSideOutOpen}
        closeSideOutAction={() => dispatch(closeSubSideOutAction())}
      >
        {SubSideOutComponent && <SubSideOutComponent />}
      </SideOut>
      <Layout>
        <IntlProviderWrapper>
          <App />
        </IntlProviderWrapper>
      </Layout>
    </Suspense>
  );
};
ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <MainApp />
      <ToastContainer />
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
