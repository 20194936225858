import * as Yup from "yup";
import valid from "card-validator";
import { urlRegex } from "./regex/urlRegex";
import { phoneNumberRegex } from "./regex/phoneRegex";
import { INVALID_CARD_NUMBER, INVALID_CVV_CODE, INVALID_EXPIRATION, INVALID_PHONE_NUMBER, INVALID_URL, INVALID_ZIP, REQUIRED_FIELD, } from "./messages";
const URL_VALIDATION_CONFIG = {
    message: INVALID_URL,
    excludeEmptyString: true,
};
const PHONE_VALIDATION_CONFIG = {
    message: INVALID_PHONE_NUMBER,
    excludeEmptyString: true,
};
export const urlValidate = Yup.string()
    .nullable()
    .matches(urlRegex, URL_VALIDATION_CONFIG);
export const requiredUrlValidate = Yup.string()
    .ensure()
    .required(REQUIRED_FIELD)
    .matches(urlRegex, INVALID_URL);
export const phoneNumberValidate = Yup.string()
    .nullable()
    .matches(phoneNumberRegex, PHONE_VALIDATION_CONFIG);
export const requiredPhoneNumberValidate = Yup.string()
    .ensure()
    .required(REQUIRED_FIELD)
    .matches(phoneNumberRegex, {
    message: INVALID_PHONE_NUMBER,
    excludeEmptyString: true,
});
export const cardHolderValidate = Yup.string().trim().required(REQUIRED_FIELD);
export const cvvValidate = Yup.string().test("cvvValidation", INVALID_CVV_CODE, function (value) {
    const cardNumber = this.parent.cardNumber?.replace(/\s/g, "");
    const cvvLength = valid.number(cardNumber)?.card?.code?.size;
    return valid.cvv(value, cvvLength)?.isValid;
});
export const cardNumberValidate = Yup.string().test("cardValidation", INVALID_CARD_NUMBER, function (value) {
    return valid.number(value?.replace(/\s/g, ""))?.isValid;
});
export const expDateValidate = Yup.string().test("expDateValidation", INVALID_EXPIRATION, function (value) {
    const { isValid } = valid.expirationDate(value);
    return isValid;
});
export const zipValidate = Yup.string()
    .trim()
    .required(REQUIRED_FIELD)
    .nullable()
    .matches(/^\d{5}(-\d{4})?$/, INVALID_ZIP);
export const validateCardNumber = (cardNumber) => valid.number(cardNumber).card;
export const passwordValidate = Yup.string()
    .trim()
    .required("Required")
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+=\-{}|":;'?\/.>,<]).{7,}$/, "Password must be 7 or more digits with at least 1 number and 1 special character.");
