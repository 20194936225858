/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/jsx-key */
import React, { createRef } from "react";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Spinner,
  Input,
  Button,
  Label,
  Col,
  FormGroup,
} from "reactstrap";
import { Link } from "react-scroll";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import classnames from "classnames";
// import throttle from "lodash/throttle";
import isObject from "lodash/isObject";

import {
  getListingData,
  getListingCounts,
  updateListingData,
  duplicateListing,
  removeListing,
  updateMultipleListings,
  updateListingType,
  getLockedListing,
  requestListingUpdate,
  sendListingReassignedEmail,
} from "../../../redux/actions/listings";
import { getUserData } from "../../../redux/actions/users";
import { createNewNote, updateNoteById } from "../../../redux/actions/notes";
import {
  addListingFile,
  getFilesByAssociation,
} from "../../../redux/actions/files";
import { searchVendorAccounts } from "../../../redux/actions/users";

import ListingDetails from "./ListingDetails";
import Promotions from "./Promotions";
import CallLogs from "./CallLogs";
// import Payments from "./Payments";
import Todos from "./Todos";
import Files from "./Files";
import ActivityHistory from "./ActivityHistory";
import moment from "moment";

import Icon from "../../../components/fts/Icons";
import ActionBar from "../../../components/fts/action-bar";
import ActionSidebar from "../../../components/fts/action-bar/action-sidebar";
import ListingSidebar from "../../../components/fts/account-listing-sidebar";
import {
  hoursListFrom,
  hoursListTo,
} from "../../../components/fts/formik/hour-input/options";
import ReassignModal from "../../../components/fts/reassign-modal";
import InfiniteScroll from "react-infinite-scroll-component";
import Checkbox from "../../../components/@vuexy/checkbox/CheckboxesVuexy";
import { Check } from "react-feather";

import withDeviceType from "../../../components/hoc/withDeviceType";

import { history } from "../../../history";

import { listingBlankValues, weekdays } from "./data";
import validationSchema from "../../../formik-validations/listing-information";
import { rawToUnix } from "../../../configs/dateConfig";
import {
  extractFormikAmenitiesData,
  extractFormikCategoriesData,
  formatFormikAmenitiesData,
  formatFormikCategoriesData,
} from "../../../utility/extract";
import { extractParameter } from "../../../utility/param-extract";
import formatHour from "../../../utility/formatHour";

// toast styles
import "react-toastify/dist/ReactToastify.css";
import "../../../assets/scss/plugins/extensions/toastr.scss";

import "../../../assets/scss/pages/account-settings.scss";
import "../../../assets/scss/pages/listing-settings.scss";
import "../../../assets/scss/pages/account-listing-shared.scss";
import "../../../assets/scss/components/fts/form-fields.scss";
import ConfirmationModal from "../../../components/fts/confirmation-modal";
import Notes from "../account-settings/Notes";

import FTSModal from "../../../components/fts/fts-modal";
import CloseButton from "../../../components/fts/CloseButton";
import AccountBox from "../../../components/fts/account-box-component";
import {
  createActivity,
  fetchActivitiesListing,
  createActivities,
} from "../../../redux/actions/activities";
import {
  addUserActivity,
  clearUserActivityList,
  setUserActivities,
} from "../../../redux/actions/user-activities";
import { setListingPromotions } from "../../../redux/actions/promotions";
import { activities } from "../../../constants";
import {
  rfc3986DecodeURIComponent,
  rfc3986EncodeURIComponent,
} from "../../../utility/encode-rfc";
import InvoicesPage from "../invoices-page";
import PaymentsList from "../payments/paymentsList";
import {
  generateListingFileName,
  getUploadUrl,
} from "../../../utility/uploadFile";
import axios from "axios";
import { createNotification } from "../../../redux/actions/notifications";
import queryString from "query-string";
import { listingTypesArray } from "../../../data/listing-types-options";
import ListingUpdateConfirmation from "../../../components/fts/listing-update-confirmation";
import MultipleListingsModal from "../../../components/fts/multiple-listings-modal";
import { updateSelectedListings } from "../../../redux/actions/ads";
import ListingsUpdatedSuccessfully from "../../../components/fts/listings-updated-successfully";
import { checkPermissions } from "../../../utility/permissionsCheck";
import AccountReport from "../account-settings/AccountReport";
import SentProofs from "../account-settings/SentProofs";
import ListingPreviewModal from "../../../components/fts/listing-preview-modal";
import DowngradingListing from "../../../components/fts/downgrading-listing";
import CancelListingUpdateModal from "../../../components/fts/cancel-listing-update-modal";
import { cancelRequestUpdate } from "../../../redux/actions/updates";
import { formatImageLink } from "../../../utility";
import { updateInvoice } from "../../../redux/actions/invoices";
import socketHandler from "../../../utility/socket";
import { generateAdsRatesKey } from "../../../utility/serialize";
import { formatAdsRates } from "../../../utility/formatAdsRates";
import { ContextMenu, MenuItem } from "react-contextmenu";
import Icons from "../../../components/fts/Icons";
import { FaRegCopy } from "react-icons/fa";
import {
  formatWorkingHoursFrom,
  formatWorkingHoursTo,
} from "../../../utility/formatWorkingHours";

let companyNamePrefix = "COPY ";

class ListingSettings extends React.Component {
  state = {
    isOpen: false,
    isOpenEditConfirmationModal: false,
    inProgress: false,
    uploadingImage: false,
    editMode: false,
    sidebar: false,
    updating: false,
    action: "",
    actionItem: null,
    activeTab: 0,
    accountData: null,
    listingData: listingBlankValues,
    imageLink: "",
    initialImageLink: "",
    confirmModalForRemoveListing: false,
    isOpenDuplicateListingModal: false,
    numberOfCopies: "",
    numberOfCopiesError: "",
    activityPreloadDate: null,
    updateListingTypePayload: null,

    isOpenReassignListingModal: false,
    isOpenReassignConfirmationModal: false,
    hasMore: true,
    accounts: [],
    page: 1,
    totalRecords: 0,
    pageCount: 0,
    pageSize: 8,
    accountSearchValue: "",
    newAccount: null,
    isSearching: false,
    promotionsCount: this.props.promotionsCount,

    isOpenMultipleListingsModal: false,
    isOpenListingUpdateConfirmationModal: false,
    updatePayload: null,
    duplicateInProgress: false,
    removeListingLoading: false,
    listingReportId: null,
    isOpenListingPreviewModal: false,
    isOpenPermissionToAporoveModal: false,
    originalWorkingHours: null,
    isOpenListingDowngradingModal: false,
    reviewListingData: false,
    isOpenCancleListingTypeChange: false,
    notifyUser: false,
    navBarCollapsed: false,
    listingLocked: false,
    collectedDataForCopy: null,
    call: null,
    havePermission: false,
  };

  firstOffsetY = 190;
  sectionOffsetY = 40;
  navBarHeight = null;
  listingSettingsTab = null;
  navigationContainer = null;

  sidebarChild = createRef();
  filesTabRef = createRef();
  filesTableGroupRef = createRef();
  formikSidebarRef = createRef();
  notes = createRef();
  invoicesRef = createRef();
  secondaryRef = createRef();
  promotionRef = createRef();
  listingFormRef = createRef();
  sentProofsRef = createRef();
  listingDataRef = createRef();

  componentDidUpdate(prevProps) {
    if (prevProps.promotionsCount !== this.props.promotionsCount) {
      this.setState({ promotionsCount: this.props.promotionsCount });
    }
  }

  setupBeforeUnloadListener = () => {
    window.addEventListener("unload", (ev) => {
      ev.preventDefault();
      const {
        match: { params },
      } = this.props;
      const { userData } = this.props;
      let firstName, lastName, userId;
      if (userData) {
        const { loggedInUser } = userData;
        firstName = loggedInUser.firstName;
        lastName = loggedInUser.lastName;
        userId = loggedInUser.id;
      }

      socketHandler.handleSocket("unSubscribeToLockListing", {
        listingId: params.listingId,
        firstName,
        lastName,
        userId,
      });
      return (ev.returnValue = "Are you sure you want to close?");
    });
  };
  componentDidMount() {
    this.setupBeforeUnloadListener();
    this.listingDataRef = this;
    const {
      match: { params },
      location,
    } = this.props;

    let { adminPermissions } = this.props;

    let havePermission = !checkPermissions(
      [
        {
          id: 4,
          subCat: [77],
        },
      ],
      adminPermissions,
    );
    this.props.getLockedListing().then(({ data }) => {
      const { listings } = data;
      const { userData } = this.props;
      let firstName, lastName, userId;
      if (userData) {
        const { loggedInUser } = userData;
        firstName = loggedInUser.firstName;
        lastName = loggedInUser.lastName;
        userId = loggedInUser.id;
      }

      let isExist = listings[params.listingId];
      if (!isExist) {
        socketHandler.handleSocket("subscribeToLockListing", {
          listingId: params.listingId,
          userId,
          firstName,
          lastName,
        });
      }
      if (isExist && isExist.userId !== userId) {
        this.setState({ listingLocked: isExist });
      }
    });

    const searchParams = queryString.parse(location.search);

    if (searchParams["update-history"]) {
      this.setState(
        {
          havePermission,
          activityPreloadDate: searchParams["update-history"],
        },
        () => {
          this.callToAction("listing_update_history", false, null);
        },
      );
    }

    this.setState({ havePermission });
    if (params && params.listingId) {
      this.getListingInfo(params.listingId);
      this.props.getListingCounts(params.listingId);
    }
    this.listingSettingsTab = document.getElementsByClassName(
      "listing-settings-tab",
    )[0];
    this.navigationContainer =
      document.getElementsByClassName("fts-nav-container")[0];

    // window.addEventListener("scroll", throttle(this.handleStickySidebar, 20));

    const todoIdParam = extractParameter("toDoId");
    if (todoIdParam) {
      this.openSidebar({ id: parseInt(todoIdParam) }, false);
    }

    const noteIdParam = extractParameter("noteId");
    if (noteIdParam) {
      this.openSidebar({ id: parseInt(noteIdParam) }, true);
    }

    const promotionIdParam = extractParameter("promotionId");
    if (promotionIdParam) {
      this.callToAction("promotion", true, { id: parseInt(promotionIdParam) });
    }

    const reportIdParam = extractParameter("reportId");
    if (reportIdParam) {
      this.previewListingReport(reportIdParam, "Report preview");
    }

    const proofIdParam = extractParameter("proof");
    if (proofIdParam) {
      this.previewListingProof(parseInt(proofIdParam), "Listing proof preview");
    }
  }

  resetImageLink() {
    this.setState({ imageLink: this.state.initialImageLink });
    this.fileInput.value = "";
  }

  setFormikSidebarRef = (ref) => {
    this.formikSidebarRef = ref;
  };

  setListingFormRef = (e) => {
    this.listingFormRef.current = e;
  };

  resetFormikForm = () => {
    this.formikSidebarRef.resetForm();
  };

  toggleNotifyUser = (notifyUser) => {
    this.setState({
      notifyUser,
    });
  };

  openSidebar = (data, note) => {
    if (data) {
      if (!note) {
        this.callToAction("todo", true, { id: data.id });
      } else {
        this.setState({
          activeTab: 0,
        });
        this.callToAction("note", true, { id: data.id });
      }
    } else if (this.props.location && this.props.location.search) {
      let data = this.props.location.data;
      if (data) {
        this.setState({
          activeTab: 0,
        });
        this.callToAction("todo", true, { id: data.toDoId });
      }
    }
  };

  updateNavWidth = () => {
    if (this.listingSettingsTab) {
      if (this.state.activeTab === 5) {
        return;
      }
      if (this.props.isSmallPhone()) {
        this.navigationContainer.style.width = "34px";
      } else if (this.props.isPhoneOrSmaller()) {
        this.navigationContainer.style.width = "62px";
      } else {
        this.navigationContainer.style.width = "226px";
      }
    }
  };

  // handleStickySidebar = () => {
  //   let actionBarHeight =
  //     document.getElementsByClassName("fts-action-bar")[0]?.clientHeight;

  //   if (this.listingSettingsTab) {
  //     this.navigationContainer.style.height = "206px";
  //     this.navigationContainer.style.minHeight = "625px";
  //     this.updateNavWidth();

  //     if (window.scrollY >= actionBarHeight) {
  //       this.listingSettingsTab.style.position = "fixed";
  //       this.listingSettingsTab.style.top = this.navBarHeight + 27 + "px";
  //     } else {
  //       this.listingSettingsTab.style.position = "relative";
  //       this.listingSettingsTab.style.top = "0px";
  //     }
  //   }
  // };

  componentWillUnmount() {
    // window.removeEventListener("scroll", this.handleStickySidebar);
    const {
      match: { params },
    } = this.props;
    const { userData } = this.props;
    let firstName, lastName, userId;
    if (userData) {
      const { loggedInUser } = userData;
      firstName = loggedInUser.firstName;
      lastName = loggedInUser.lastName;
      userId = loggedInUser.id;
    }

    socketHandler.handleSocket("unSubscribeToLockListing", {
      listingId: params.listingId,
      firstName,
      lastName,
      userId,
    });

    this.props.clearUserActivityList();
  }

  formatData = (data) => {
    if (!data) return this.state.listingData;

    const workingHours = {};

    this.setState({
      originalWorkingHours: data?.workingHours,
    });
    weekdays.forEach(({ day }) => {
      if (!data?.workingHours || data.alwaysOpen) return;

      let [from, to] = data?.workingHours
        ? // eslint-disable-next-line no-unsafe-optional-chaining
          data?.workingHours[`${day.toLowerCase()}`].split("-")
        : ["", ""];
      from = formatHour(from?.trim() || "");
      to = formatHour(to?.trim() || "");

      // if day is empty string
      if (data?.workingHours[`${day.toLowerCase()}`] === "") {
        // set from & to to null
        from = null;
        to = null;
      }

      workingHours[`${day}From`] = !!from
        ? hoursListFrom.find(({ value }) => value === from) ?? ""
        : null;
      workingHours[`${day}To`] = !!to
        ? hoursListTo.find(({ value }) => value === to) ?? ""
        : null;
    });

    if (data?.adNotes?.length) {
      const note = data?.adNotes.find((itm) => itm.isAdmin);
      data.adminNoteId = note?.id;
      data.adminNote = note?.note;
      data.adminNoteType = note?.noteType?.length ? note.noteType[0] : null;
      data.noteTypeId = note?.noteType?.length
        ? note.noteType[0].notetypeId
        : null;
      data.noteIsSticky = note?.isSticky;
    } else {
      data.adminNote = "";
      data.noteIsSticky = false;
    }
    if (data.adsRates.length) {
      data.adsRates.map((e) => {
        const key = generateAdsRatesKey(e.rate.name, e.rate.id);

        data[key] = e.value;
        return e;
      });
    }
    const skipHours =
      !Object.values(workingHours).filter((e) => e).length && !data.alwaysOpen;
    this.setState({ imageLink: data.image });
    this.setState({ initialImageLink: data.image });

    return {
      ...data,
      name: rfc3986DecodeURIComponent(data.name),
      isItPaidFor: data.paymentStatus === "paid" ? true : false,
      createdAt: moment(data.createdAt).format("MMM D, YYYY"),
      renewalDate: moment.unix(data.renewalTimestamp).format("MMM D, YYYY"),
      updatedAt: moment(data.updatedAt).format("MMM D, YYYY"),
      addressInfo: {
        address: data.address,
        city: data.city,
        state: data.state,
        zip: data.zip,
      },
      mileMarker: data.milemarker,
      geoLocation: { lat: data.latitude, lng: data.longitude },
      description: rfc3986DecodeURIComponent(data.description),
      includeInAuthorizedPromotions: !!data.includeInAuthorizedPromotions,
      includeInFeaturedVendors: !!data.includeInFeaturedVendors,
      excludeRenewalEmails: !!data.excludeRenewalEmails,
      excludeDowngrades: !!data.excludeDowngrades,
      workingHours: {
        ...workingHours,
        skipHours,
        open24hrs: !!data.alwaysOpen,
        mobileRoadServiceOpen: data.mobileRoadServiceOpen,
        towingRecoveryOpen: data.towingRecoveryOpen,
        specialHours: data.specialHours,
      },
      categories: {
        ...formatFormikCategoriesData({
          categories: data.adsCategories,
          subCategories: data.adsSubCategories,
        }),
      },
      ...formatFormikAmenitiesData(data.adsAmenities),
      ...formatFormikAmenitiesData(data.adsServiceAmenities, true),
    };
  };

  getListingInfo = (listingId) => {
    this.setState({ inProgress: true });
    this.props
      .getListingData(listingId)
      .then(({ data }) => {
        if (data.listing === null || data.listing === undefined) {
          history.push("/404");
          return;
        }

        const formattedData = this.formatData(data.listing);

        if (formattedData.userId) {
          this.props
            .getUserData(formattedData.userId)
            .then(({ data }) => {
              if (data) {
                this.setState({ accountData: data });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
        // this.props.getFilesByAssociation("listings", data.listing.id, 1, 20);

        this.setState({
          editMode: false,
          inProgress: false,
          listingData: formattedData,
          imageLink: formattedData.image,
        });
      })
      .catch((err) => {
        console.log("====================================");
        console.log(err);
        console.log("====================================");
        this.setState({ inProgress: false });
      });
  };

  setFileInputRef = (ref) => {
    this.fileInput = ref;
  };

  setFileInputRefVal = (value) => {
    this.fileInput.value = value;
    this.setState({ imageLink: "" });
  };

  onImageUpdate = async (e, newImage) => {
    if (newImage) {
      return this.setState({
        imageLink: newImage,
      });
    }
    let { listingData } = this.state;

    this.setState({ uploadingImage: true });

    for (let fileForUpload of e.target.files) {
      let fileName = generateListingFileName(
        listingData,
        fileForUpload.type.split("/").pop(),
      );
      const url = await getUploadUrl({
        filePath: fileName,
        mimeType: fileForUpload.type,
      });
      const arrayBuffer = await fileForUpload.arrayBuffer();

      let blob = new Blob([new Uint8Array(arrayBuffer)], {
        type: fileForUpload.type,
      });

      let mimeType = fileForUpload.type;

      await axios.put(url, blob, {
        "Content-Type": mimeType,
        headers: {
          "Content-Type": mimeType,
        },
        contentType: mimeType,
      });

      await this.props.addListingFile({
        listingId: listingData.id,
        userId: listingData.userId,
        fileUrl: fileName,
        size: fileForUpload.size / 1000000,
      });
      this.setState({
        imageLink: fileName,
        uploadingImage: false,
      });
    }
  };

  setActiveTab = (activeTab) => {
    window.scrollTo(0, 0);
    this.setState({ activeTab });
  };

  getListingTypeName = (value) => {
    let key = listingTypesArray.filter((type) => type.value === value);

    return key[0].label;
  };

  toggleEdit = () => {
    this.setState({ editMode: !this.state.editMode }, () => {
      this.state.editMode && this.listingFormRef.current.resetForm();
    });
  };

  toggleEditConfirmationModal = () => {
    if (this.listingFormRef?.current?.dirty) {
      this.setState({
        isOpenEditConfirmationModal: !this.state.isOpenEditConfirmationModal,
      });
    } else {
      this.toggleEdit();
    }
  };

  onRemoveListing = () => {
    if (this.state.removeListingLoading) return;
    if (this.state.listingData.user.isDeleted) {
      toast.error(
        "The account this listing belongs to has been deleted. To enable this listing, please first enable the account.",
        {
          position: toast.POSITION.TOP_RIGHT,
        },
      );
      return this.setState({ confirmModalForRemoveListing: false });
    }

    this.setState({ removeListingLoading: true });

    this.props
      .removeListing(this.state.listingData.id)
      .then(() => {
        toast.success("Listing successfully deleted!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        let activityPayload = {
          status: 1,
          userId: this.state.listingData.userId,
          activityType: this.props.activitiesTypes.DELETED_LISTING,
          activity: activities.removeListing
            .replace(
              "{{admin_name}}",
              `${this.props.userData.loggedInUser.firstName} ${this.props.userData.loggedInUser.lastName}`,
            )
            .replace("{{listingId}}", `${this.state.listingData.id}`),
          adminUserId: this.props.userData.loggedInUser.id,
          iconName: "Listings",
        };
        this.props.createActivity(activityPayload);
        this.setState({ removeListingLoading: false });
        history.push(`/accounts/${this.state.listingData.userId}`);
      })
      .catch((e) => {
        this.setState({ removeListingLoading: false });
        console.log("ERROR: ", e);
        toast.error("Something went wrong. ", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  onPreviewListing = () => {};

  getFirstErrorKey = (object, keys = []) => {
    const firstErrorKey = Object.keys(object)[0];
    if (isObject(object[firstErrorKey])) {
      return this.getFirstErrorKey(object[firstErrorKey], [
        ...keys,
        firstErrorKey,
      ]);
    }
    return [...keys, firstErrorKey].join(".");
  };

  onSubmitError = (errors) => {
    const key = this.getFirstErrorKey(errors);
    let element;

    if (document.getElementsByName(key).length) {
      element = document.getElementsByName(key)[0];
    } else {
      element = document.getElementById(key);
    }
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }

    toast.error("Unable to proceed. Invalid form!", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  callToAction = (action, update, item, listingData) => {
    if (!action) return;
    this.setState({
      action,
      actionItem: item,
      sidebar: true,
      updating: !!update,
      listingData: listingData || this.state.listingData,
    });
  };

  handleSidebar = (bool, update, closeWithoutPrompt) => {
    if (bool === false && this.formikSidebarRef?.dirty && !closeWithoutPrompt) {
      return this.setState({ isOpen: true });
    } else {
      this.setState({ sidebar: bool, updating: !!update });
    }
    if (bool === false) {
      this.setState({
        reviewListingData: false,
      });

      const { action } = this.state;
      if (
        action === "change_listing_type" ||
        action === "update_listing" ||
        action === "preview_listing_details" ||
        action === "payment_overview"
      ) {
        this.getListingInfo(this.state.listingData?.id);
        this.setState({ action: "" });
      }
    }
  };

  closeSideOutAction = () => {
    this.setState({ sidebar: false, update: false, isOpen: false });
  };

  onKeyDown(e) {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  }

  setPromotionsCount = (number) => {
    this.setState({
      promotionsCount: this.state.promotionsCount + parseInt(number),
    });
  };

  generatePayload = (values) => {
    const { userCategories: categories, userSubCategories: subCategories } =
      extractFormikCategoriesData(values.categories);

    const hoursOfOperation = {
      alwaysOpen: values.workingHours.open24hrs ? 1 : 0,
      specialHours: {
        towingRecovery: !!values.workingHours.towingRecoveryOpen,
        mobileRoad: !!values.workingHours.mobileRoadServiceOpen,
      },
      weekDays: weekdays.map(({ day }) => ({
        day,
        // "08:00 PM",
        from: formatWorkingHoursFrom(values, day),
        // "08:00 PM",
        to: formatWorkingHoursTo(values, day),
      })),
    };
    if (values.renewalDate) {
      if (typeof values.renewalDate === "object") {
        values.renewalTimestamp = rawToUnix(values.renewalDate[0].toString());
        values.renewalLaterDate = rawToUnix(values.renewalDate[0].toString());
      }
    }

    values.adsRates = formatAdsRates(values, this.props.rates);

    const payload = {
      ...values,
      alwaysOpen: values.workingHours.open24hrs ? 1 : 0,
      workingHours: undefined,
      imageLink: this.state.imageLink,
      image: this.state.imageLink,
      ...values.workingHours,
      adsCategories: [...categories],
      adsSubCategories: [...subCategories],
      ...values.addressInfo,
      milemarker: values.mileMarker,
      latitude: values.geoLocation.lat,
      longitude: values.geoLocation.lng,
      ...extractFormikAmenitiesData(values),
      priceId:
        typeof values.priceId === "object"
          ? values.priceId.value
          : values.priceId,
      state:
        typeof values.addressInfo.state === "object"
          ? values.addressInfo.state.value
          : values.addressInfo.state,
      adminStatus:
        typeof values.adminStatus === "object"
          ? values.adminStatus.value
          : values.adminStatus,
      status:
        typeof values.status === "object" ? values.status.value : values.status,
      hoursOfOperation,
      paymentStatus: values.isItPaidFor ? "paid" : "not_paid",
      onHold: 0,
      onHoldCount: 0,
      onHoldEndDate: moment().toISOString(),
      name: rfc3986EncodeURIComponent(values.name),
      description: rfc3986EncodeURIComponent(values.description),
      userId: this.state.accountData?.id,
    };

    delete payload.categories;

    return payload;
  };

  saveUpdatePayload = (values) => {
    let payload = this.generatePayload(values);

    this.setState({
      updatePayload: payload,
    });
  };

  confirmMultipleListingsUpdate = async () => {
    try {
      this.setState({
        inProgress: true,
      });
      let selectedListings = [
        ...this.props.selectedListings,
        this.state.listingData.id,
      ];
      let numberOfListingsChanged = selectedListings.length;
      let values = this.listingFormRef?.current?.values;
      const { userListings } = this.props;
      const listings = selectedListings.map((id) => {
        let list = userListings.find((e) => e.id === id);
        return {
          image: list.image,
          id: list.id,
        };
      });
      let uploadPromises = [];
      let updateListingIds = [];
      let listingsPromises = [];
      let listingsInPromises = [];
      const initialValues = this.listingFormRef.current.initialValues;
      let updatePayload = {};

      for (let key in initialValues) {
        if (initialValues[key] !== this.listingFormRef.current.values[key]) {
          updatePayload[key] = this.listingFormRef.current.values[key];
        }
      }

      let url = formatImageLink(this.state.imageLink);
      let { data: imageData } = await axios.get(url, {
        responseType: "arraybuffer",
      });

      for (let listing of listings) {
        updateListingIds.push(listing.id);
        if (this.state.imageLink) {
          listing.image = generateListingFileName(listing);
          let fileName = listing.image;
          let mimeType = "image/png";
          url = await getUploadUrl({
            filePath: fileName,
            mimeType,
          });
          const arrayBuffer = imageData;
          let blob = new Blob([new Uint8Array(arrayBuffer)], {
            type: mimeType,
          });
          uploadPromises.push(
            axios.put(url, blob, {
              "Content-Type": mimeType,
              headers: {
                "Content-Type": mimeType,
              },
              contentType: mimeType,
            }),
          );
        }

        listingsInPromises.push(listing);

        listingsPromises.push(1);

        if (uploadPromises.length === 10) {
          await Promise.all(uploadPromises);
          uploadPromises = [];
        }
        if (listingsPromises.length === 10) {
          const {
            userCategories: categories,
            userSubCategories: subCategories,
          } = extractFormikCategoriesData(values?.categories);
          const payload = {
            updateListingIds,
            listings: listingsInPromises,
            imageLink: this.state.imageLink,
            image: this.state.imageLink,
            userId: this.state.listingData.userId,
            ...updatePayload,
            adsCategories: [...categories],
            adsSubCategories: [...subCategories],
            ...extractFormikAmenitiesData(values),
          };
          await this.props.updateMultipleListings(payload);
          listingsPromises = [];
          updateListingIds = [];
          listingsInPromises = [];
        }
      }

      const { userCategories: categories, userSubCategories: subCategories } =
        extractFormikCategoriesData(values?.categories);
      await Promise.all(uploadPromises);
      await this.props.updateMultipleListings({
        updateListingIds,
        listings: listingsInPromises,
        imageLink: this.state.imageLink,
        image: this.state.imageLink,
        userId: this.state.listingData.userId,
        ...updatePayload,
        adsCategories: [...categories],
        adsSubCategories: [...subCategories],
        ...extractFormikAmenitiesData(values),
      });

      console.log("selectedListings: ", selectedListings);

      this.updateOrCreateListingNote(this.state.updatePayload);

      let bulkActivityPayloadMain = {
        adId: this.state.listingData.id,
        status: 1,
        userId: this.state.listingData.userId,
        activityType: this.props.activitiesTypes.BULK_LISTING_UPDATE,

        activity: activities.bulkListingUpdate
          .replace(
            "{{admin_name}}",
            `${this.props.userData.loggedInUser.firstName} ${this.props.userData.loggedInUser.lastName}`,
          )
          .replace("{{listingId}}", this.state.listingData.id)
          .replace(
            "{{listingIDs}}",
            selectedListings.toString().replaceAll(",", ", "),
          ),
        adminUserId: this.props.userData.loggedInUser.id,
        iconName: "Updates",
      };

      this.props.createActivity(bulkActivityPayloadMain).then(() => {
        this.props
          .fetchActivitiesListing(this.state.listingData.id)
          .then(({ data }) => this.props.setUserActivities(data));
      });

      const bulkActivityPayloadIndividual = {
        bulkActivityPayloadMain,
        userId: null,
      };

      let bulkActivities = selectedListings
        .map((listingId) => {
          return {
            ...bulkActivityPayloadIndividual,
            adId: listingId,
            activityType: this.props.activitiesTypes.BULK_LISTING_UPDATE,
            activity: activities.bulkListingUpdateIndividual
              .replace(
                "{{admin_name}}",
                `${this.props.userData.loggedInUser.firstName} ${this.props.userData.loggedInUser.lastName}`,
              )
              .replace("{{listingId}}", listingId)
              .replace("{{mainListingId}}", this.state.listingData.id),
          };
        })
        .filter(
          (bulkActivity) => bulkActivity.adId !== this.state.listingData.id,
        );

      let bulkActivityPayload = {
        ...bulkActivityPayloadMain,
        bulkActivities,
      };

      await this.props.createActivities(bulkActivityPayload);

      this.setState({
        updatePayload: null,
      });
      this.props.updateSelectedListings([]);
      this.toggleListingUpdateConfirmationModal();
      this.toggleListingsUpdatedSuccessfully();
      this.props.getListingCounts(this.state.listingData.id);
      this.setState({
        editMode: false,
        numberOfUpdatedListings: numberOfListingsChanged,
      });
      this.getListingInfo(this.state.listingData.id);
      toast.success("Listings succesffuly updated!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (e) {
      console.log(e);

      toast.error("Something went wrong. Please try again.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      this.setState({ inProgress: false });
    }
  };

  updateOrCreateListingNote(values) {
    if (values.adminNoteId) {
      const notesData = {
        id: values.adminNoteId,
        note: values.adminNote || "",
        isAdmin: true,
        noteType:
          typeof values.adminNoteType === "string"
            ? values.adminNoteType
            : values.adminNoteType?.value || "",
        noteTypeId: Array.isArray(values.adminNoteType)
          ? values.adminNoteType.length
            ? values.adminNoteType[0]?.id
            : null
          : values.adminNoteType?.id,
        isSticky: values.noteIsSticky,
      };

      this.props.updateNoteById(notesData);
    } else {
      const notesData = {
        note: values.adminNote || "",
        isAdmin: true,
        noteType:
          typeof values.adminNoteType === "string"
            ? values.adminNoteType
            : values.adminNoteType?.value || "",
        // userId: null,
        noteTypeId: Array.isArray(values.adminNoteType)
          ? values.adminNoteType.length
            ? values.adminNoteType[0]?.id
            : null
          : values.adminNoteType?.id || 1,
        adId: values.id,
        isSticky: values.noteIsSticky,
        parentId: null,
      };

      this.props.createNewNote(notesData);
    }
  }

  onFormSubmission = (values) => {
    if (this.state.inProgress) {
      return;
    }

    this.setState({ inProgress: true });

    let payload = this.generatePayload(values);

    let { listingData } = this.state;
    let { userData } = this.props;

    let upgradePayingActivity;
    if (listingData.priceId === 5 && payload.priceId !== 5) {
      upgradePayingActivity = {
        adId: this.state.listingData.id,
        status: 2,
        activityType: this.props.activitiesTypes.UPGRADED_LISTING, // "upgradeToPaying",
        activity: activities.upgradeToPayingListing
          .replace(
            "{{admin_name}}",
            `${this.props.userData.loggedInUser.firstName} ${this.props.userData.loggedInUser.lastName}`,
          )
          .replace("{{listingId}}", `${this.state.listingData.id}`),
        adminUserId: this.props.userData.loggedInUser.id,
        iconName: "Listings",
      };
    }

    let upgradeActivityTypes = [
      "",
      this.props.activitiesTypes.UPGRADE_LISTING_TO_PREMIUM,
      this.props.activitiesTypes.UPGRADE_LISTING_TO_STANDARD,
      this.props.activitiesTypes.UPGRADE_LISTING_TO_BASIC_PLUS,
      this.props.activitiesTypes.UPGRADE_LISTING_TO_BASIC,
      // "upgradeToPremium",
      // "upgradeToStandard",
      // "upgradeToBasicPlus",
      // "upgradeToBasic",
      "",
    ];
    let downgradeActivityTypes = [
      "",
      "",
      this.props.activitiesTypes.DOWNGRADE_LISTING_TO_STANDARD,
      this.props.activitiesTypes.DOWNGRADE_LISTING_TO_BASIC_PLUS,
      this.props.activitiesTypes.DOWNGRADE_LISTING_TO_BASIC,
      this.props.activitiesTypes.DOWNGRADE_LISTING_TO_FREE,
      // "downgradeToPremium",
      // "downgradeToStandard",
      // "downgradeToBasicPlus",
      // "downgradeToBasic",
      // "downgradeToFree",
    ];
    const upgradeActivityPayload = {
      adId: listingData.id,
      status: 2,
      userId: listingData.userId,
      activityType: upgradeActivityTypes[payload.priceId],
      iconName: "Listings",
      adminUserId: userData.loggedInUser.id,
    };

    let activityPayload = {
      adId: this.state.listingData.id,
      status: 1,
      userId: this.state.listingData.userId,
      activityType: this.props.activitiesTypes.UPDATED_LISTING,
      activity: activities.updateListing
        .replace(
          "{{admin_name}}",
          `${this.props.userData.loggedInUser.firstName} ${this.props.userData.loggedInUser.lastName}`,
        )
        .replace("{{listingId}}", this.state.listingData.id)
        .replace("{{companyName}}", this.state.listingData.name)
        .replace("{{city}}", this.state.listingData.city)
        .replace("{{state}}", this.state.listingData.state),
      adminUserId: this.props.userData.loggedInUser.id,
      iconName: "Updates",
    };

    const promises = [
      this.props.updateListingData(payload.id, payload),
      this.props.createActivity(activityPayload),
    ];

    if (upgradePayingActivity)
      promises.push(this.props.createActivity(upgradePayingActivity));

    if (payload.priceId < listingData.priceId) {
      promises.push(
        this.props.createActivity({
          ...upgradeActivityPayload,
          activity: activities[upgradeActivityTypes[payload.priceId]]
            .replace(
              "{{admin_name}}",
              `${userData.loggedInUser.firstName} ${userData.loggedInUser.lastName}`,
            )
            .replace(
              "{{oldType}}",
              `${this.getListingTypeName(this.state.listingData.priceId)}`,
            )
            .replace("{{listing_id}}", `${listingData.id}`),
        }),
      );
    }
    if (payload.priceId > listingData.priceId) {
      promises.push(
        this.props.createActivity({
          ...upgradeActivityPayload,
          activityType: downgradeActivityTypes[payload.priceId],
          activity: activities[downgradeActivityTypes[payload.priceId]]
            .replace(
              "{{admin_name}}",
              `${userData.loggedInUser.firstName} ${userData.loggedInUser.lastName}`,
            )
            .replace(
              "{{oldType}}",
              `${this.getListingTypeName(this.state.listingData.priceId)}`,
            )
            .replace("{{listing_id}}", `${listingData.id}`),
        }),
      );
      //upgrade
    }

    Promise.all(promises)
      .then(([{ data: listingData }]) => {
        this.updateOrCreateListingNote(values);

        this.props
          .fetchActivitiesListing(listingData.listing.id)
          .then(({ data }) => this.props.setUserActivities(data));
        this.props.getListingCounts(listingData.listing.id);
        toast.success(listingData?.message || "Listing successfully updated", {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.setState({ inProgress: false });
        this.getListingInfo(payload.id);
        this.invoicesRef && this.invoicesRef.refreshTable();
        this.secondaryRef && this.secondaryRef.refreshTable();
        // this.filesTableGroupRef && this.filesTableGroupRef.onSubmitQuery();
      })
      .catch((err) => {
        console.log(err);
        this.setState({ inProgress: false });
        toast.error(err.response?.data?.errors || "", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  getListingTitle = () => {
    return this.state.inProgress ? (
      <Spinner size="sm" />
    ) : !!this.state.listingData ? (
      `${this.state.listingData.name}`
    ) : (
      ""
    );
  };

  onPromotionCreate = () => {
    this.promotionRef.onSubmitQuery();
    // let allPromotion = [...this.props.listingPromotionData];

    // let mainPromotion = { ...this.props.listingPromotionData[0] };
    // let newPromotion = { ...mainPromotion, ...promotion };
    // allPromotion.push(newPromotion);
    // let payload = {
    //   rows: allPromotion,
    //   count: this.props.promotionsCount + 1,
    // };
    // this.props.setListingPromotions(payload);
  };

  setActionItem = (actionItem) => {
    this.setState({ actionItem: { ...actionItem } });
  };

  onNotesListUpdate = () => {
    this.sidebarChild.fetchNotes(this.state, this.setActionItem);
    this.notes.fetchListingNotes();
  };

  onTodoListUpdate = () => {
    this.sidebarChild.fetchTodos(this.state, this.setActionItem);
    this.notes.fetchListingNotes();
  };

  toggleModal = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  onModalClosed = () => {
    console.log("closed modal!");
  };

  toggle = (tab) => {
    if (tab !== 2 && this.filesTabRef?.closePopover) {
      this.filesTabRef.closePopover();
    }
    this.setState({
      activeTab: tab,
    });
  };

  confirmCloseEdit = () => {
    if (this.listingFormRef?.current?.dirty) {
      this.listingFormRef?.current?.resetForm &&
        this.listingFormRef.current.resetForm();
      this.toggleEditConfirmationModal();
      this.toggleEdit();
      this.resetImageLink && this.resetImageLink();
    }
  };

  toggleDuplicateListingModal = () => {
    this.setState({
      isOpenDuplicateListingModal: !this.state.isOpenDuplicateListingModal,
      numberOfCopies: "",
      numberOfCopiesError: "",
    });
  };

  toggleReassignListingModal = () => {
    if (!this.state.isOpenReassignListingModal) {
      this.setState({
        page: 1,
        totalRecords: 0,
        pageCount: 0,
        accounts: [],
        accountSearchValue: "",
        newAccount: null,
      });
    }
    this.setState(
      {
        isOpenReassignListingModal: !this.state.isOpenReassignListingModal,
      },
      () => {
        this.updateAccountSearchResults();
      },
    );
  };

  toggleReassignConfirmationModal = () => {
    this.setState({
      isOpenReassignConfirmationModal:
        !this.state.isOpenReassignConfirmationModal,
    });
  };

  toggleListingsUpdatedSuccessfully = () => {
    this.setState({
      isOpenListingsUpdatedSuccessfully:
        !this.state.isOpenListingsUpdatedSuccessfully,
    });
  };

  toggleMultipleListingsModal = () => {
    this.setState({
      isOpenMultipleListingsModal: !this.state.isOpenMultipleListingsModal,
    });
  };

  toggleListingUpdateConfirmationModal = () => {
    this.setState({
      isOpenListingUpdateConfirmationModal:
        !this.state.isOpenListingUpdateConfirmationModal,
    });
  };

  toggleListingDowngradingModal = (updateListingTypePayload = null) => {
    this.setState({
      isOpenListingDowngradingModal: !this.state.isOpenListingDowngradingModal,
      updateListingTypePayload,
    });
  };

  toggleCancelListingTypeChange = () => {
    this.setState({
      isOpenCancleListingTypeChange: !this.state.isOpenCancleListingTypeChange,
    });
  };

  updateSearchQuery = (e) => {
    this.setState({
      isSearching: true,
    });
    this.setState(
      { accounts: [], accountSearchValue: e.target.value, page: 1 },
      () => {
        clearTimeout(this.debounce);
        this.debounce = setTimeout(() => {
          this.updateAccountSearchResults();
        }, 300);
      },
    );
  };

  handleDuplicate = async () => {
    const { listingData, numberOfCopies, duplicateInProgress } = this.state;
    if (duplicateInProgress) return;
    if (!numberOfCopies || !parseInt(numberOfCopies)) {
      return this.setState({ numberOfCopiesError: "This field is required." });
    }

    if (parseInt(numberOfCopies) > 25) {
      return this.setState({
        numberOfCopiesError: "Number of duplicates is limited to 25.",
      });
    }

    const getIds = (data) => {
      if (!data || !data.length) return [];
      return data.map((e) => e.id);
    };

    const hoursOfOperation = {
      alwaysOpen: listingData.workingHours.open24hrs ? 1 : 0,
      specialHours: {
        towingRecovery: !!listingData.workingHours.towingRecoveryOpen,
        mobileRoad: !!listingData.workingHours.mobileRoadServiceOpen,
      },
      weekDays: weekdays.map(({ day }) => ({
        day,
        // "08:00 PM",
        from: formatWorkingHoursFrom(listingData, day),
        // "08:00 PM",
        to: formatWorkingHoursTo(listingData, day),
      })),
    };

    const payload = {
      towingRecoveryOpen: listingData?.workingHours?.towingRecoveryOpen || 0,
      mobileRoadServiceOpen:
        listingData?.workingHours?.mobileRoadServiceOpen || 0,
      specialHours: listingData.specialHours,
      approved: listingData.approved,
      specials: listingData.specials,
      email: listingData.email,
      website2: listingData.website2,
      website: listingData.website,
      afterHours: listingData.afterHours,
      fax: listingData.fax,
      alwaysOpen: hoursOfOperation.alwaysOpen,
      phoneNumber: listingData.phoneNumber,
      image: listingData.image,
      description: listingData.description,
      address: listingData.address,
      milemarker: listingData.milemarker,
      interstate: listingData.interstate,
      mileMarker: listingData.mileMarker,
      name: companyNamePrefix + listingData.name,
      street: listingData.street,
      city: listingData.city,
      state: listingData.state,
      zip: listingData.zip,
      serviceType: listingData.serviceType,
      priceId: listingData.priceId,
      userId: listingData.userId,
      numberOfCopies,
      adsAmenities: getIds(listingData.adsAmenities),
      adsCategories: getIds(listingData.adsCategories),
      adsServiceAmenities: getIds(listingData.adsServiceAmenities),
      adsSubCategories: getIds(listingData.adsSubCategories),
      hoursOfOperation,
      adsRates: listingData?.adsRates,
    };

    this.setState({ duplicateInProgress: true });

    try {
      const { data } = await this.props.duplicateListing(payload);
      this.toggleDuplicateListingModal();
      await this.logDuplicateActivity(numberOfCopies, data.createdIds);

      let { data: activitiesData } = await this.props.fetchActivitiesListing(
        listingData.id,
      );
      this.props.setUserActivities(activitiesData);

      this.setState({ duplicateInProgress: false });

      return toast.success(data.message || "Listings successfully created", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (e) {
      this.setState({ duplicateInProgress: false });
      console.log(e);
      const message = e?.response?.statusText
        ? e.response.statusText
        : "Error while creating listings or activities, please reload and try again!";
      return toast.error(message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  handleReassign = async () => {
    if (this.state.inProgress) {
      return;
    }
    this.setState({ inProgress: true });
    this.handleSendListingReassignedEmail();
    console.log(this.state.listingData);
    // /* eslint-disable  */
    // debugger;
    this.props
      .updateListingData(this.state.listingData.id, {
        ...this.state.listingData,
        adsCategories: this.state.listingData.adsCategories.length
          ? this.state.listingData.adsCategories.map((e) => e.id)
          : [],
        adsSubCategories: this.state.listingData.adsSubCategories.length
          ? this.state.listingData.adsSubCategories.map((e) => e.id)
          : [],
        userId: this.state.newAccount.id,
        name: rfc3986EncodeURIComponent(this.state.listingData.name),
      })
      .then(() => {
        this.toggleReassignConfirmationModal();
        toast.success("Listings successfully reassigned", {
          position: toast.POSITION.TOP_RIGHT,
        });

        let fromAccount = `#${this.state.accountData.id} - ${this.state.accountData.companyName} - ${this.state.accountData.city} - ${this.state.accountData.state}`;
        let toAccount = `#${this.state.newAccount.id} - ${this.state.newAccount.companyName} - ${this.state.newAccount.city} - ${this.state.newAccount.state}`;
        let activity = activities.convertListing
          .replace(
            "{{admin_name}}",
            `${this.props.userData.loggedInUser.firstName} ${this.props.userData.loggedInUser.lastName}`,
          )
          .replace("{{toAccount}}", toAccount)
          .replace("{{fromAccount}}", fromAccount)
          .replace("{{fromHref}}", `/accounts/${this.state.accountData.id}`)
          .replace("{{toHref}}", `/accounts/${this.state.newAccount.id}`);

        const createActivityPayload = {
          adId: this.state.listingData.id,
          userId: this.state.accountData.id,
          status: 2,
          // activityType: "reassignListing",
          activityType: this.props.activitiesTypes.REASSIGNED_LISTING,
          activity: rfc3986EncodeURIComponent(activity),
          adminUserId: this.props.userData.loggedInUser.id,
          iconName: "Listings",
        };

        this.setState({
          accountData: this.state.newAccount,
        });

        this.props
          .createActivity(createActivityPayload)
          .then((data) => {
            this.props.addUserActivity(data.data);

            let activity = activities.reassignListing
              .replace(
                "{{admin_name}}",
                `${this.props.userData.loggedInUser.firstName} ${this.props.userData.loggedInUser.lastName}`,
              )
              .replace("{{listingId}}", this.state.listingData.id)
              .replace("{{fromAccount}}", fromAccount)
              .replace("{{href}}", `/accounts/${this.state.accountData.id}`);

            const newActivityPayload = {
              userId: this.state.newAccount.id,
              status: 2,
              // activityType: "reassignListing",
              activityType: this.props.activitiesTypes.REASSIGNED_LISTING,
              activity: rfc3986EncodeURIComponent(activity),
              adminUserId: this.props.userData.loggedInUser.id,
              iconName: "Listings",
            };

            this.props
              .createActivity(newActivityPayload)
              .then(() => {})
              .catch(() => {
                toast.error("Oops! Something went wrong. Please try again.", {
                  position: toast.POSITION.TOP_RIGHT,
                });
                this.setState({ inProgress: false });
              });
          })
          .catch((err) => {
            console.log(err);
            toast.error("Oops! Something went wrong. Please try again.", {
              position: toast.POSITION.TOP_RIGHT,
            });
            this.setState({ inProgress: false });
            this.toggleReassignConfirmationModal();
          });

        this.setState({ inProgress: false });
      })
      .catch((err) => {
        console.log(err);
        toast.error("Oops! Something went wrong. Please try again.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.setState({ inProgress: false });
        this.toggleReassignConfirmationModal();
      });
  };

  handleSendListingReassignedEmail = async () => {
    const payload = {
      listingData: this.state.listingData,
      previousAccountId: this.state.accountData.id,
      newAccountId: this.state.newAccount.id,
    };

    return await this.props.sendListingReassignedEmail(payload);
  };

  fetchFiles = (
    key,
    accountId,
    page,
    pageSize,
    dateFrom,
    dateTo,
    fileType,
    searchString,
  ) => {
    return this.props.getFilesByAssociation(
      key,
      accountId,
      page,
      pageSize,
      dateFrom,
      dateTo,
      fileType,
      searchString,
    );
  };

  updateAccountSearchResults = () => {
    this.setState({
      isSearching: true,
    });
    const { page, pageSize, sortAccountVendor, accountSearchValue } =
      this.state;
    return this.props
      .searchVendorAccounts({
        page,
        resultsPerPage: pageSize,
        sort: sortAccountVendor?.length
          ? [
              { colId: "adminLastContactDateForSort", sort: "ASC" },
              ...sortAccountVendor,
            ]
          : [
              { colId: "adminLastContactDateForSort", sort: "ASC" },
              { colId: "createdAt", sort: "desc" },
            ],
        searchString: accountSearchValue,
        fields: ["idString", "companyName", "city", "state", "email"],
      })
      .then(
        ({
          data: {
            result: { hits, nbHits },
          },
        }) => {
          if (hits?.length === 0) {
            this.setState({
              hasMore: false,
            });
          }
          this.setState({
            columnDefs: this.accountColumns,
            accounts: [...this.state.accounts, ...hits],
            totalRecords: nbHits,
            page: page + 1,
            pageCount: Math.ceil(nbHits / pageSize),
          });

          this.setState({
            isSearching: false,
          });
        },
      );
  };

  onReassignChange = (newAccount, account) => {
    if (newAccount?.id === account.id) {
      this.setState({ newAccount: null });
    } else {
      this.setState({
        newAccount: account,
      });
    }
  };

  logDuplicateActivity = async (numberOfCopies, createdIds) => {
    const { listingData } = this.state;

    let activityPayload = {
      status: 1,
      userId: listingData.userId,
      adId: listingData.id,
      activityType: this.props.activitiesTypes.DUPLICATED_LISTING,
      adminUserId: this.props.userData?.loggedInUser?.id,
      iconName: "Listings",
    };

    if (numberOfCopies > 1) {
      activityPayload.activity = activities.duplicateMultiple
        .replace(
          "{{admin_name}}",
          `${this.props.userData?.loggedInUser?.firstName} ${this.props.userData?.loggedInUser?.lastName}`,
        )
        .replace("{{listingId}}", listingData.id)
        .replace("{{city}}", listingData.city)
        .replace("{{state}}", listingData.state)
        .replace("{{duplicatesAmount}}", numberOfCopies)
        .replace("{{listingsIds}}", createdIds.join(", "));
    } else {
      activityPayload.activity = activities.duplicateOnce
        .replace(
          "{{admin_name}}",
          `${this.props.userData?.loggedInUser?.firstName} ${this.props.userData?.loggedInUser?.lastName}`,
        )
        .replace("{{listingId}}", listingData.id)
        .replace("{{city}}", listingData.city)
        .replace("{{state}}", listingData.state);
    }

    return this.props.createActivity(activityPayload);
  };

  setActivityPreloadDate = (date) => {
    this.setState({
      activityPreloadDate: date,
    });
  };

  previewListingReport = (id, title) => {
    this.setState(
      {
        listingReportId: id,
        listingReportTitle: title,
      },
      () => {
        this.callToAction("report_preview", false);
      },
    );
  };

  previewListingProof = (id, title) => {
    this.setState(
      {
        proofToLoad: id,
        listingPreviewModalTitle: title,
      },
      () => {
        this.toggleListingPreviewModal();
      },
    );
  };

  toggleListingPreviewModal = () => {
    this.setState({
      isOpenListingPreviewModal: !this.state.isOpenListingPreviewModal,
    });
  };

  setListingPreviewModalTitle = (modalType) => {
    this.setState({
      modalType: modalType,
    });

    if (modalType === "sendListingProof") {
      this.setState({
        listingPreviewModalTitle: "Send Listing proof",
      });
    }
  };

  handleReviewListingData = (value) => {
    this.setState({
      reviewListingData: value,
    });
  };

  handleNavBarCollapse = () => {
    this.setState({
      navBarCollapsed: !this.state.navBarCollapsed,
    });
  };

  collectDataForCopy = (data, call) => {
    this.setState({
      collectedDataForCopy: data,
      call,
    });
  };

  onRequestUpdate = async () => {
    const { listingData, accountData } = this.state;
    const { requestListingUpdate } = this.props;

    if (!listingData) return;

    const payload = {
      recipientName: accountData?.firstName,
      recipient: listingData?.email,
      companyName: listingData?.name,
      city: listingData.city,
      state: listingData.state,
      userId: listingData?.userId,
    };

    try {
      await requestListingUpdate(payload);
    } catch (err) {
      console.log("error", err);

      toast.error("Oops! Something went wrong. Please try again.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  render() {
    let {
      inProgress,
      uploadingImage,
      editMode,
      action,
      actionItem,
      activeTab,
      sidebar,
      updating,
      imageLink,
      listingData,
      isOpen,
      isOpenEditConfirmationModal,
      accountSearchValue,
      newAccount,
      isOpenMultipleListingsModal,
      isOpenListingsUpdatedSuccessfully,
      listingPreviewModalTitle,
      navBarCollapsed,
      listingLocked,
    } = this.state;

    const {
      match: { params },
    } = this.props;

    const isListingDuplicated = listingData.isDuplicated;

    //TODO: This needs to be called within componentShouldUpdate and not on every render ( optimization thing )
    this.updateNavWidth();

    const firstOffsetY = this.navBarHeight + this.firstOffsetY;
    const sectionOffsetY = this.navBarHeight + this.sectionOffsetY;

    const upgradeInProgress =
      this.state.listingData?.update &&
      this.state.listingData?.update?.status === 1 &&
      this.state.listingData?.update?.type === "upgrade_listing";

    const iconSize = 20;

    const navItemProps = {
      className: "nav-link",
      activeClass: "active",
      spy: true,
      smooth: true,
      duration: 1000,
    };

    const navLinkClassName = "nav-link";
    const viewModeNavItems = [
      {
        iconName: "Listings",
        label: "Listing details",
        disabled: !checkPermissions(
          [{ id: 2, subCat: [15] }],
          this.props.adminPermissions,
        ),
      },
      {
        iconName: "Promotion",
        label: `Promotions (${this.state.promotionsCount || 0})`,
        disabled: !checkPermissions(
          [{ id: 2, subCat: [16] }],
          this.props.adminPermissions,
        ),
      },
      {
        iconName: "File",
        label: `Invoices (${this.props.invoicesCount ?? 0})`,
        disabled: !checkPermissions(
          [{ id: 2, subCat: [17] }],
          this.props.adminPermissions,
        ),
      },
      {
        iconName: "Payment",
        label: `Payments (${this.props.totalRecordsPayments ?? 0})`,
        disabled: !checkPermissions(
          [{ id: 2, subCat: [18] }],
          this.props.adminPermissions,
        ),
      },
      {
        iconName: "Phone",
        label: "Call logs",
        // disabled: !checkPermissions(
        //   [{ id: 2, subCat: [19] }],
        //   this.props.adminPermissions
        // ),
        disabled: true,
      },
      {
        iconName: "Report",
        label: "Reporting",
        disabled: !checkPermissions(
          [{ id: 2, subCat: [20] }],
          this.props.adminPermissions,
        ),
      },
      {
        iconName: "SendProof",
        label: "Sent proofs",
        disabled: !checkPermissions(
          [{ id: 2, subCat: [51] }],
          this.props.adminPermissions,
        ),
      },
      {
        iconName: "Todos",
        label: `Todo's (${this.props.counts.todosCount ?? 0})`,
        // navLink: "/tasks-management",
        disabled: !checkPermissions(
          [{ id: 2, subCat: [21] }],
          this.props.adminPermissions,
        ),
      },
      {
        iconName: "TodoNote",
        label: `Notes (${this.props.counts.notesCount ?? 0})`,
        disabled: !checkPermissions(
          [{ id: 2, subCat: [22] }],
          this.props.adminPermissions,
        ),
      },
      {
        iconName: "Files",
        label: `Files (${this.props.counts.filesCount ?? 0})`,
        disabled: !checkPermissions(
          [{ id: 2, subCat: [23] }],
          this.props.adminPermissions,
        ),
      },
      {
        iconName: "ListingActivity",
        label: "Activity history",
        disabled: !checkPermissions(
          [{ id: 2, subCat: [24] }],
          this.props.adminPermissions,
        ),
      },
    ];
    const editModeNavItems = [
      {
        offset: -firstOffsetY,
        to: "section1",
        label: "Listing info",
      },
      {
        offset: -sectionOffsetY,
        to: "section2",
        label: "Business details",
      },
      {
        offset: -sectionOffsetY,
        to: "section3",
        label: "Hours of operation",
      },
      {
        offset: -sectionOffsetY,
        to: "section4",
        label: "Services",
      },
      {
        offset: -sectionOffsetY,
        to: "section-service-amenities",
        label: "Service Amenities",
      },
      {
        offset: -sectionOffsetY,
        to: "section6",
        label: "Amenities",
      },
      {
        offset: -sectionOffsetY,
        to: "section-note",
        label: "Note",
      },
      {
        offset: -sectionOffsetY,
        to: "section7",
        label: "Images",
      },
      {
        offset: -sectionOffsetY,
        to: "section-promotions",
        label: "Promotions",
      },
    ];

    const listingTabContents = [
      <ListingDetails
        {...{
          inProgress,
          editMode,
          uploadingImage,
          imageLink,
          listingData,
          validationSchema,
        }}
        updateListingTypePayload={this.state.updateListingTypePayload}
        toggleDuplicateListingModal={this.toggleDuplicateListingModal}
        toggleReassignListingModal={this.toggleReassignListingModal}
        toggleEditConfirmationModal={this.toggleEditConfirmationModal}
        listingFormRef={this.setListingFormRef}
        resetImageLink={this.resetImageLink.bind(this)}
        callToAction={this.callToAction}
        onFormSubmission={this.onFormSubmission}
        onSubmitError={this.onSubmitError}
        toggleEdit={this.toggleEdit}
        onRemoveListing={() =>
          this.setState({ confirmModalForRemoveListing: true })
        }
        onRequestUpdate={this.onRequestUpdate}
        onPreviewListing={this.onPreviewListing}
        noteLabelValue={listingData.adminNoteType}
        onImageUpdate={this.onImageUpdate}
        setFileInputRef={this.setFileInputRef}
        setFileInputRefVal={this.setFileInputRefVal}
        handleListingTypeChange={this.handleListingTypeChange}
        onKeyDown={this.onKeyDown}
        accountInfo={this.state.accountData}
        getFilesByAssociation={this.fetchFiles}
        listingId={this.props.match.params.listingId}
        toggleMultipleListingsModal={this.toggleMultipleListingsModal}
        saveUpdatePayload={this.saveUpdatePayload}
        toggleListingPreviewModal={this.toggleListingPreviewModal}
        setListingPreviewModalTitle={this.setListingPreviewModalTitle}
        reviewListingData={this.props.reviewListingData}
        listingLocked={listingLocked}
        sidebar={this.state.sidebar}
        action={action}
        collectDataForCopy={this.collectDataForCopy}
        upgradeInProgress={upgradeInProgress}
      />,
      <Promotions
        accountInfo={this.state.accountData}
        listingId={parseInt(this.props.match.params.listingId)}
        onRef={(ref) => (this.promotionRef = ref)}
        setPromotionsCount={this.setPromotionsCount}
      />,
      <InvoicesPage
        listingId={parseInt(this.props.match.params.listingId)}
        invoiceToOpen={parseInt(extractParameter("invoiceId"))}
        invoicesRef={(e) => (this.invoicesRef = e)}
        secondaryRef={(e) => (this.secondaryRef = e)}
        accountData={this.state.accountData}
        listingData={this.state.listingData}
        isListing
        listingDataRef={this.listingDataRef}
        getListingCounts={this.props.getListingCounts}
      />,
      <PaymentsList
        listingId={parseInt(this.props.match.params.listingId)}
        invoiceToOpen={parseInt(extractParameter("invoiceId"))}
        invoicesRef={(e) => (this.invoicesRef = e)}
        accountData={this.state.accountData}
      />,
      <CallLogs />,
      <AccountReport
        callToAction={this.callToAction}
        listingId={params.listingId}
        listingData={this.state.listingData}
        previewListingReport={this.previewListingReport}
      />,
      <SentProofs
        sentProofsRef={(ref) => (this.sentProofsRef = ref)}
        listingData={this.state.listingData}
        callToAction={this.callToAction}
      />,
      <Todos
        listingData={this.state.listingData}
        listingId={params.listingId}
      />,
      <Notes
        title="Listing notes"
        isListing={true}
        listingId={this.props.match.params.listingId}
        getUserData={this.props.getUserData}
        onRef={(ref) => (this.notes = ref)}
        sidebarChild={this.sidebarChild}
        adminData={this.props.userData}
        state={this.state}
        accountData={{
          ...this.state.accountData,
          userId: this.props.userData.id,
        }}
        openSidebar={this.openSidebar}
        callToAction={this.callToAction}
      />,
      <Files
        listingData={this.state.listingData}
        accountData={this.state.accountData}
        filesTabRef={(ref) => (this.filesTabRef = ref)}
        filesTableGroupRef={(ref) => (this.filesTableGroupRef = ref)}
        getFilesByAssociation={this.fetchFiles}
        accountId={listingData?.id}
        listingId={this.props.match.params.listingId}
        imageLink={imageLink}
        toggleNotifyUser={this.toggleNotifyUser}
        notifyUser={this.state.notifyUser}
        onImageUpdate={this.onImageUpdate}
        getListingInfo={this.getListingInfo}
      />,
      <ActivityHistory
        listingId={listingData?.id}
        callToAction={this.callToAction}
        activityPreloadDate={this.state.activityPreloadDate}
        setActivityPreloadDate={this.setActivityPreloadDate}
      />,
    ];

    const opt = {
      1: { value: 1, label: "Premium" },
      2: { value: 2, label: "Standard" },
      3: { value: 3, label: "Basic Plus" },
      4: { value: 4, label: "Basic" },
      5: { value: 5, label: "Free" },
    };
    let requestedUpdate = {};

    if (this.state.listingData?.update) {
      requestedUpdate = JSON.parse(
        this.state.listingData?.update.requestedUpdate,
      );
    }

    return (
      <React.Fragment>
        {this.state.havePermission && (
          <div className={"listing-no-permission"}>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <h1>You don't have permission for this private listing</h1>
          </div>
        )}
        <div className=" fts-action-bar-wrapper">
          <ActionBar
            adminPermissions={this.props.adminPermissions}
            isDeleted={listingData.isDeleted}
            isListingDuplicated={isListingDuplicated}
            listing
            changedListingType={
              this.state.listingData?.update &&
              this.state.listingData?.updateStartAt
            }
            changeType={
              requestedUpdate?.priceId === this.state.listingData.priceId
                ? "update"
                : this.state.listingData?.priceId < requestedUpdate?.priceId
                ? "downgrade"
                : "upgrade"
            }
            changedListingTypeMessage={
              requestedUpdate?.priceId === this.state.listingData.priceId
                ? `There is an update for renewal date from ${moment
                    .unix(this.state.listingData?.renewalTimestamp)
                    .format("ll")} to  ${moment
                    .unix(requestedUpdate?.renewalTimestamp)
                    .format("ll")} in progress that
                has not yet been paid. Payment was due on ${moment(
                  this.state.listingData?.updateStartAt,
                ).format("ll")}`
                : `There is an ${
                    this.state.listingData?.priceId < requestedUpdate?.priceId
                      ? "downgrade"
                      : "upgrade"
                  } from ${opt[
                    this.state.listingData?.priceId || 1
                  ].label.toUpperCase()} to ${opt[
                    requestedUpdate?.priceId || 1
                  ].label.toUpperCase()} in progress that
                has not yet been paid. Payment was due on ${moment(
                  this.state.listingData?.updateStartAt,
                ).format("ll")}`
            }
            callToAction={this.callToAction}
            setActiveTab={this.setActiveTab}
            toggleListingPreviewModal={this.toggleListingPreviewModal}
            setListingPreviewModalTitle={this.setListingPreviewModalTitle}
            toggleCancelListingTypeChange={this.toggleCancelListingTypeChange}
            listingLocked={listingLocked}
          />
        </div>

        <div className="acc-list-flex-wrapper">
          <div className="nav-vertical listing-setting-wrapper">
            <div
              style={{
                maxWidth: activeTab === 7 || navBarCollapsed ? 65 : 216,
                marginLeft: activeTab === 7 || 0,
                transition: "all  0.2s ease-in-out",
              }}
              className="fts-nav-container"
            >
              <Nav
                className={classnames(
                  "listing-settings-tab nav-left mr-0 mr-sm-3",
                  {
                    "edit-mode": editMode,
                  },
                )}
                tabs
              >
                <NavItem
                // style={{ marginLeft: activeTab === 5 ? "-20px" : 0 }}
                >
                  <NavLink
                    style={{ marginLeft: activeTab === 7 ? "-2px" : 0 }}
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    <Icon name="ArrowLeft" size={24} />
                    {!navBarCollapsed && activeTab !== 7 && (
                      <span className="d-md-inline-block d-none align-middle ml-1">
                        Back
                      </span>
                    )}
                  </NavLink>
                  {this.state.accountData &&
                    activeTab !== 7 &&
                    !navBarCollapsed && (
                      <NavLink>
                        <AccountBox
                          accountInfo={this.state.accountData}
                          listingNav={true}
                        />
                      </NavLink>
                    )}
                </NavItem>
                {editMode &&
                  editModeNavItems.map(({ offset, to, label }) => (
                    <NavItem key={label}>
                      <Link {...{ offset, to }} {...navItemProps}>
                        <span className="d-md-inline-block d-none align-middle ml-1">
                          {label}
                        </span>
                      </Link>
                    </NavItem>
                  ))}
                {!editMode &&
                  viewModeNavItems.map(
                    ({ iconName, label, disabled, navLink }, index) => (
                      <NavItem key={label}>
                        <NavLink
                          style={
                            disabled
                              ? { opacity: 0.2, cursor: "default" }
                              : null
                          }
                          className={classnames(navLinkClassName, {
                            active: activeTab === index,
                            isPhone: iconName === "Phone",
                          })}
                          onClick={() => {
                            if (disabled) return;
                            if (navLink) {
                              if (navLink !== "/tasks-management") {
                                return this.props.history.push(navLink);
                              } else {
                                return this.props.history.push({
                                  pathname: navLink,
                                  search: `?listingId=${this.state.listingData.id}`,
                                });
                              }
                            }
                            if (
                              label !== "Files" &&
                              this.filesTabRef?.closePopover
                            ) {
                              this.filesTabRef.closePopover();
                            }
                            this.setActiveTab(index);
                          }}
                        >
                          <Icon name={iconName} size={iconSize} />
                          <span className="d-md-inline-block d-none align-middle ml-1">
                            {navBarCollapsed || activeTab === 7 ? "" : label}
                          </span>
                        </NavLink>
                      </NavItem>
                    ),
                  )}

                <NavItem>
                  <NavLink
                    onClick={() => {
                      this.handleNavBarCollapse();
                    }}
                    className={`nav-collapse-button no-select ${
                      navBarCollapsed ? "collapsed-button" : ""
                    }`}
                    disabled={activeTab === 7}
                  >
                    <Icon name="CollapseLeft" size={16} color={"#7D8C91"} />
                    <span
                      className="d-md-inline-block d-none align-middle ml-1"
                      style={{ color: "#8C919F" }}
                    >
                      {navBarCollapsed || activeTab === 7 ? "" : "Collapse"}
                    </span>
                  </NavLink>
                </NavItem>
              </Nav>
            </div>

            <TabContent activeTab={activeTab}>
              {listingTabContents.map((component, index) => {
                if (activeTab === 9 && index === 9) {
                  return (
                    <TabPane key={9} tabId={9}>
                      {component}
                    </TabPane>
                  );
                }
                if (activeTab === 6 && index === 6) {
                  return (
                    <TabPane key={6} tabId={6}>
                      {component}
                    </TabPane>
                  );
                }

                if (activeTab === 7 && index === 7) {
                  return (
                    <TabPane key={7} tabId={7}>
                      {component}
                    </TabPane>
                  );
                }

                if (activeTab === 10 && index === 10) {
                  return (
                    <TabPane key={10} tabId={10}>
                      {component}
                    </TabPane>
                  );
                }

                if (index !== 9 && index !== 10 && index !== 6 && index !== 7) {
                  return (
                    <TabPane key={index} tabId={index}>
                      {component}
                    </TabPane>
                  );
                }
                return null;
              })}
            </TabContent>
            <ActionSidebar
              isListing={true}
              invoicesRef={this.invoicesRef}
              secondaryRef={this.secondaryRef}
              toggleTableOverlay={this.promotionRef.toggleTableOverlay}
              onSubmitQuery={this.promotionRef.onSubmitQuery}
              setFormikSidebarRef={this.setFormikSidebarRef}
              resetFormikForm={this.resetFormikForm}
              adminData={this.props.userData}
              listingData={listingData}
              accountInfo={this.state.accountData}
              show={sidebar}
              action={action}
              updating={updating}
              actionItem={actionItem}
              handleSidebar={this.handleSidebar}
              promotionType="listingPromotion"
              listingName={listingData.name}
              listingId={listingData.id}
              getListingInfo={this.getListingInfo}
              onTodoListUpdate={this.onTodoListUpdate}
              onNotesListUpdate={this.onNotesListUpdate}
              filesTableGroupRef={this.filesTableGroupRef}
              callToAction={this.callToAction}
              showAccountBox
              accountData={this.state.accountData}
              promotionRef={this.promotionRef}
              setPromotionsCount={this.setPromotionsCount}
              setActivityPreloadDate={this.setActivityPreloadDate}
              activityPreloadDate={this.state.activityPreloadDate}
              listingReportId={this.state.listingReportId}
              listingReportTitle={this.state.listingReportTitle}
              key={this.state.listingReportTitle}
              sentProofsRef={this.sentProofsRef}
              toggleListingDowngradingModal={this.toggleListingDowngradingModal}
              reviewListingData={this.state.reviewListingData}
              handleReviewListingData={this.handleReviewListingData}
              originalWorkingHours={this.state.originalWorkingHours}
              setActiveTab={this.setActiveTab}
            />
          </div>
          <ListingSidebar
            adminID={this.props.userData.loggedInUser.id}
            onRef={(ref) => (this.sidebarChild = ref)}
            listingID={this.props.match.params.listingId}
            callToAction={this.callToAction}
            show={activeTab === 0 && !editMode}
            updateNotesList={this.onNotesListUpdate}
            isListing={true}
            switchSideTab={this.toggle}
            openSidebar={this.openSidebar}
            activities={[]}
          />
        </div>

        <FTSModal
          isOpen={this.state.isOpenDuplicateListingModal}
          modalClasses={"fts-modal-new"}
          centered={true}
          closeModal={this.toggleDuplicateListingModal}
          footerCTA={() => {
            return (
              <>
                <Button
                  onClick={this.toggleDuplicateListingModal}
                  color="secondary"
                >
                  Cancel
                </Button>
                <Button
                  onClick={this.handleDuplicate}
                  disabled={!!this.state.duplicateInProgress}
                  color="primary"
                >
                  {this.state.duplicateInProgress ? "Loading..." : "Duplicate"}
                </Button>
              </>
            );
          }}
          headerCTA={() => {
            return (
              <div className={"d-flex"}>
                <h4> Duplicate listing </h4>
                <Col className="text-right">
                  <CloseButton onClick={this.toggleDuplicateListingModal} />
                </Col>
              </div>
            );
          }}
          title={"Duplicate listing"}
        >
          <div>
            <p className={"mb-3"}>
              How many times do you want to duplicate this listing?
            </p>
            <Label for="cat-label mb-2">Number of duplicates*</Label>
            <div>
              <Input
                type="number"
                valid={false}
                className="w-50"
                onFocus={() => this.setState({ numberOfCopiesError: "" })}
                value={this.state.numberOfCopies}
                placeholder="Number of duplicates"
                onChange={({ target }) =>
                  this.setState({ numberOfCopies: target.value })
                }
              />
              <div className="formik-validation-error w-100 mb-2 ">
                {this.state.numberOfCopiesError}
              </div>
            </div>
            <div
              style={{ height: 1, backgroundColor: "#E3E7EB", width: "100%" }}
            />
          </div>
        </FTSModal>
        <FTSModal
          isOpen={this.state.isOpenReassignListingModal}
          modalClasses={"fts-reassign-acc-list-modal"}
          centered={true}
          size="lg"
          footerCTA={() => {
            return (
              <>
                <Button
                  onClick={this.toggleReassignListingModal}
                  color="secondary"
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    this.toggleReassignConfirmationModal();
                    this.toggleReassignListingModal();
                  }}
                  color="primary"
                  disabled={!newAccount}
                >
                  Reassign
                </Button>
              </>
            );
          }}
        >
          <div className="fts-reassign-acc-list-modal-body">
            <span className="fts-reassign-acc-list-modal-body-wrapper">
              <span className="fts-reassign-acc-list-modal-title">
                <h3>Reassign Listing to another account</h3>
                <CloseButton onClick={this.toggleReassignListingModal} />
              </span>
              <FormGroup className="position-relative has-icon-left">
                <Input
                  type="text"
                  placeholder="Search account"
                  className="fts-reassign-acc-list-modal-search"
                  onChange={this.updateSearchQuery}
                  value={accountSearchValue}
                />
                <div className="form-control-position">
                  <Icon name="Search" size={18} />
                </div>
              </FormGroup>
              <div
                style={{ height: "350px", overflowY: "scroll" }}
                id={"scrollableDiv_ReassignListing"}
              >
                <InfiniteScroll
                  dataLength={this.state.accounts.length}
                  next={this.updateAccountSearchResults}
                  hasMore={true}
                  endMessage={
                    <p style={{ textAlign: "center" }}>
                      <b>You have seen all accounts</b>
                    </p>
                  }
                  loader={
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        marginTop: "1rem",
                        marginBottom: "2rem",
                      }}
                    >
                      {accountSearchValue &&
                        this.state.accounts.length === 0 &&
                        this.state.isSearching === false && (
                          <p>No results found</p>
                        )}

                      {this.state.hasMore && (
                        <>
                          <span
                            style={{ marginRight: "1rem", color: "#32383A" }}
                          >
                            <Spinner size="sm" />
                          </span>
                          <span style={{ color: "#32383A" }}>
                            Loading more items
                          </span>
                        </>
                      )}
                    </div>
                  }
                  scrollableTarget={"scrollableDiv_ReassignListing"}
                >
                  {this.state.accounts.map((account, index) => {
                    if (account.id !== this.state.listingData.userId) {
                      return (
                        <div
                          className="reassign-row"
                          key={index + 1000}
                          onClick={() => {
                            this.onReassignChange(newAccount, account);
                          }}
                        >
                          <Checkbox
                            className="reassign-checkbox"
                            color="#647074"
                            icon={<Check className="vx-icon" size={16} />}
                            defaultChecked={false}
                            checked={newAccount?.id === account.id}
                          />
                          #{account.id} - {account.companyName}, {account.city},{" "}
                          {account.state}, {account.email}
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                </InfiniteScroll>
              </div>
            </span>
          </div>
        </FTSModal>

        {isOpenMultipleListingsModal && (
          <MultipleListingsModal
            toggleMultipleListingsModal={this.toggleMultipleListingsModal}
            toggleListingUpdateConfirmationModal={
              this.toggleListingUpdateConfirmationModal
            }
            accountId={this.state.accountData.id}
            listingId={this.state.listingData.id}
          />
        )}

        <ListingUpdateConfirmation
          isOpen={this.state.isOpenListingUpdateConfirmationModal}
          toggleListingUpdateConfirmationModal={
            this.toggleListingUpdateConfirmationModal
          }
          toggleMultipleListingsModal={this.toggleMultipleListingsModal}
          confirmMultipleListingsUpdate={this.confirmMultipleListingsUpdate}
          inProgress={this.state.inProgress}
          oldListingData={this.state.listingData}
          newListingData={this.state.saveUpdatePayload}
        />

        <ReassignModal
          closeModal={this.toggleReassignConfirmationModal}
          confirmModal={this.handleReassign}
          reassignListing
          oldAccount={this.state.accountData}
          newAccount={newAccount}
          isOpen={this.state.isOpenReassignConfirmationModal}
        />

        <ListingsUpdatedSuccessfully
          isOpen={isOpenListingsUpdatedSuccessfully}
          closeModal={() => {
            this.toggleListingsUpdatedSuccessfully();
            this.setState({ numberOfUpdatedListings: 0 });
          }}
          confirmModal={() => {
            this.toggleListingsUpdatedSuccessfully();
            this.setState({ numberOfUpdatedListings: 0 });
          }}
          numberOfUpdatedListings={this.state.numberOfUpdatedListings}
        />

        {this.state.isOpenListingDowngradingModal && (
          <DowngradingListing
            isOpen={true}
            listingData={this.state.listingData}
            updateListingTypePayload={this.state.updateListingTypePayload}
            closeModal={() => {
              this.getListingInfo(this.state.listingData?.id);
              this.toggleListingDowngradingModal();
            }}
            confirmModal={() => {
              this.handleReviewListingData(true);
              this.toggleListingDowngradingModal();
              const { updateListingTypePayload, listingData, accountData } =
                this.state;
              let { endAt, newListingType } = updateListingTypePayload;

              let newListingData = {
                ...listingData,
                renewalTimestamp: moment.unix(endAt[0]) / 1000000,
                priceId: newListingType,
              };

              this.callToAction(
                "update_listing",
                false,
                {
                  listingData,
                  accountData,
                },
                newListingData,
              );
            }}
          />
        )}

        <ContextMenu
          style={{
            minWidth: 250,
            zIndex: 1000,
            display: !this.state.collectedDataForCopy && "none",
          }}
          id="contextMenuListingSettings"
          preventHideOnScroll={false}
        >
          <MenuItem
            onClick={() =>
              navigator.clipboard.writeText(this.state.collectedDataForCopy)
            }
          >
            <FaRegCopy size={18} className="copy" />
            <span className="ml-1 text-dark">Copy</span>
          </MenuItem>

          <MenuItem>
            <a
              className="text-decoration-none text-dark d-flex align-items-center w-100"
              href={`tel:${this.state.collectedDataForCopy}`}
            >
              <Icons name="PhoneBlue" />
              <div className="ml-1">
                Call - {this.state.collectedDataForCopy}
              </div>
            </a>
          </MenuItem>
        </ContextMenu>

        <CancelListingUpdateModal
          isOpen={this.state.isOpenCancleListingTypeChange}
          toggleCancelListingTypeChange={this.toggleCancelListingTypeChange}
          inProgress={this.state.cancelUpgradeDowngradeInProgress}
          closeModal={() =>
            this.setState({ isOpenCancleListingTypeChange: false })
          }
          listingData={this.state.listingData}
          getListingInfo={this.getListingInfo}
          invoicesRef={this.invoicesRef}
          secondaryRef={this.secondaryRef}
          getListingCounts={this.props.getListingCounts}
        />

        {this.state.isOpenListingPreviewModal && (
          <ListingPreviewModal
            modalTitle={listingPreviewModalTitle}
            isOpen={this.state.isOpenListingPreviewModal}
            toggleListingPreviewModal={this.toggleListingPreviewModal}
            listingData={
              this.state.listingData?.update
                ? JSON.parse(this.state.listingData?.update?.requestedUpdate)
                : this.state.listingData
            }
            accountData={this.state.accountData}
            originalWorkingHours={this.state.originalWorkingHours}
            modalType={this.state.modalType}
            proofToLoad={this.state.proofToLoad}
            companyName={this.state?.listingData?.name}
          />
        )}

        <ConfirmationModal
          title="Unsaved Changes"
          message="Are you sure you want to proceed with this action?"
          onClose={this.onModalClosed}
          closeModal={this.toggleModal}
          confirmModal={this.closeSideOutAction}
          {...{ isOpen }}
        />

        <ConfirmationModal
          title="Unsaved Changes"
          message="Are you sure you want to proceed with this action?"
          closeModal={this.toggleEditConfirmationModal}
          confirmModal={this.confirmCloseEdit}
          isOpen={isOpenEditConfirmationModal}
        />

        <ConfirmationModal
          title="Confirmation"
          message={`Are you sure you want to ${
            listingData.isDeleted ? "enable" : "delete"
          }  this listing?`}
          closeModal={() =>
            this.setState({ confirmModalForRemoveListing: false })
          }
          confirmModal={this.onRemoveListing}
          isOpen={this.state.confirmModalForRemoveListing}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    adminPermissions: state.auth.login.permissions,
    userData: state.auth.login.values,
    counts: state.listingCounts,
    promotionsCount: state.promotions.totalActiveRecordsListing,
    listingPromotionData: state.promotions.listingPromotionData,
    invoicesCount: state.invoices.allInvoicesCount,
    totalRecordsPayments: state.invoices.allPaymentsCount,
    activitiesTypes: state.essentialLists.activitiesTypes,
    selectedListings: state.listingCounts.selectedListings,
    userListings: state.accountCounts.userListings,
    rates: state.essentialLists.rates,
  };
};

const DeviceTypeWrapper = withDeviceType(ListingSettings);

export default connect(mapStateToProps, {
  duplicateListing,
  getListingData,
  getListingCounts,
  getUserData,
  getFilesByAssociation,
  updateListingData,
  createNewNote,
  updateNoteById,
  searchVendorAccounts,
  addUserActivity,
  createActivity,
  clearUserActivityList,
  setListingPromotions,
  removeListing,
  fetchActivitiesListing,
  setUserActivities,
  addListingFile,
  createNotification,
  updateSelectedListings,
  updateMultipleListings,
  createActivities,
  updateListingType,
  cancelRequestUpdate,
  updateInvoice,
  getLockedListing,
  requestListingUpdate,
  sendListingReassignedEmail,
})(DeviceTypeWrapper);
