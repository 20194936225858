export const ButtonTypes = {
    contained: "contained",
    outlined: "outlined",
    text: "text",
};
export const ButtonDirections = {
    row: "row",
    column: "column",
    columnReverse: "column-reverse",
    rowReverse: "row-reverse",
};
export const ButtonStatuses = {
    idle: "idle",
    hover: "hover",
    focus: "focus",
    active: "active",
    disabled: "disabled",
};
export const ButtonColorsValue = {
    accent: "accent",
    onBrand: "onBrand",
    error: "error",
    success: "success",
    warning: "warning",
    neutral: "neutral",
};
export const ButtonSizes = {
    md: "md",
    sm: "sm",
    xs: "xs",
};
export const ButtonProviders = {
    google: "google",
    facebook: "facebook",
    paypal: "paypal",
    apple: "apple",
};
