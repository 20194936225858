import styled from "styled-components";
import { LineHeightByDevice, LineHeightVariantsValues, VariantByDevice, VariantsValues, WeightSize, } from "@find-truck-service/types/ui/text";
import { device } from "@find-truck-service/types/ui/breakpoints";
import { defaultProps, defaultStyleProps } from "../default";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { linkStyle } from "@find-truck-service/ui/src/FtsLink/FtsLink";
const textProps = ["variant", "color", "weight", "lineHeightVariant"];
export const TextStyle = ({ weight = WeightSize["regular"], variant = VariantsValues["text-md"], color = ColorsValue["greyscale-1000"], lineHeightVariant = LineHeightVariantsValues.tight, }) => {
    let res = `
                margin: 0;
                color:${color || ColorsValue["red-brand-50"]};
                letter-spacing: 0;
                font-weight: ${weight};
                line-height: ${LineHeightByDevice[variant][lineHeightVariant].lg};
                font-size: ${VariantByDevice[variant].lg};
                a { ${linkStyle} }`;
    for (let [key, val] of Object.entries(device))
        res += `@media ${val} {
                font-size: ${VariantByDevice[variant][key]};
                line-height: ${LineHeightByDevice[variant][lineHeightVariant][key]};
              };`;
    return res;
};
const textAndDefaultProps = [...defaultProps, ...textProps];
export const H1 = styled.h1.withConfig({
    shouldForwardProp: (prop) => !textAndDefaultProps.includes(prop),
}) `
  ${TextStyle};
  ${defaultStyleProps};
`;
export const H2 = styled.h2.withConfig({
    shouldForwardProp: (prop) => !textAndDefaultProps.includes(prop),
}) `
  ${TextStyle};
  ${defaultStyleProps};
`;
export const H3 = styled.h3.withConfig({
    shouldForwardProp: (prop) => !textAndDefaultProps.includes(prop),
}) `
  ${TextStyle};
  ${defaultStyleProps};
`;
export const H4 = styled.h4.withConfig({
    shouldForwardProp: (prop) => !textAndDefaultProps.includes(prop),
}) `
  ${TextStyle};
  ${defaultStyleProps};
`;
export const H5 = styled.h5.withConfig({
    shouldForwardProp: (prop) => !textAndDefaultProps.includes(prop),
}) `
  ${TextStyle};
  ${defaultStyleProps};
`;
export const H6 = styled.h6.withConfig({
    shouldForwardProp: (prop) => !textAndDefaultProps.includes(prop),
}) `
  ${TextStyle};
  ${defaultStyleProps};
`;
export const Span = styled.span.withConfig({
    shouldForwardProp: (prop) => !textAndDefaultProps.includes(prop),
}) `
  ${TextStyle};
  ${defaultStyleProps};
`;
export const P = styled.p.withConfig({
    shouldForwardProp: (prop) => !textAndDefaultProps.includes(prop),
}) `
  ${TextStyle};
  ${defaultStyleProps};
`;
export const Text = styled.text.withConfig({
    shouldForwardProp: (prop) => !textAndDefaultProps.includes(prop),
}) `
  ${TextStyle};
  ${defaultStyleProps};
`;
