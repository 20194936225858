import React from "react";
import { Span } from "../../../FtsText";
import styled from "styled-components";
import FtsIcons from "../../../FtsIcons/FtsIcons";
import { FtsWrapper } from "../../../FtsWrapper/FtsWrapper";
import { IconNames } from "../../../FtsIcons/Types/iconNames";
import { Spacing } from "@find-truck-service/types/ui/spacing";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import { ButtonColorsValue, ButtonSizes, ButtonTypes } from "@find-truck-service/types/ui/button";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { MessageButtonsSm } from "@find-truck-service/ui/src/components/react/Message/components/MessageButtonsSm";
export const messageTypes = {
    info: "info",
    success: "success",
    warning: "warning",
    error: "error",
    neutral: "neutral",
};
export const MessageButtonColor = {
    [messageTypes.info]: ButtonColorsValue.accent,
    [messageTypes.neutral]: ButtonColorsValue.accent,
    [messageTypes.success]: ButtonColorsValue.success,
    [messageTypes.error]: ButtonColorsValue.error,
    [messageTypes.warning]: ButtonColorsValue.warning,
};
const MessageIcon = {
    [messageTypes.info]: IconNames.Info,
    [messageTypes.success]: IconNames.Check,
    [messageTypes.warning]: IconNames.AlertTriangle,
    [messageTypes.error]: IconNames.AlertOctagon,
    [messageTypes.neutral]: IconNames.AlertOctagon,
};
const MessageIconWrapperBackgroundColor = {
    [messageTypes.info]: ColorsValue["blue-accent-500"],
    [messageTypes.success]: ColorsValue["green-success-500"],
    [messageTypes.warning]: ColorsValue["yellow-warning-300"],
    [messageTypes.error]: ColorsValue["red-error-500"],
    [messageTypes.neutral]: ColorsValue["greyscale-800"],
};
const MessageBackgroundColor = {
    [messageTypes.info]: ColorsValue["blue-accent-50"],
    [messageTypes.success]: ColorsValue["green-success-50"],
    [messageTypes.warning]: ColorsValue["yellow-warning-50"],
    [messageTypes.error]: ColorsValue["red-error-50"],
    [messageTypes.neutral]: ColorsValue["greyscale-50"],
};
const MessageBorderColor = {
    [messageTypes.info]: ColorsValue["blue-accent-300"],
    [messageTypes.success]: ColorsValue["green-success-300"],
    [messageTypes.warning]: ColorsValue["yellow-warning-300"],
    [messageTypes.error]: ColorsValue["red-error-300"],
    [messageTypes.neutral]: ColorsValue["greyscale-100"],
};
const IconWrapper = styled(FtsWrapper) `
  background-color: ${(props) => MessageIconWrapperBackgroundColor[props.type]};
  border-radius: ${Spacing["4xxl"]};
  padding: ${Spacing["2xs"]};
  display: inline-flex;
  width: fit-content;
  height: fit-content;
`;
export const Message = (props) => {
    const showConfirmButton = !!props.confirmButtonLabel && !!props.onConfirm;
    const showCancelButton = !!props.cancelButtonLabel && !!props.onCancel;
    const showCloseButton = !!props.onClose;
    const showButtons = showConfirmButton || showCancelButton || showCloseButton;
    return (React.createElement(FtsWrapper, { flex: 1, py: "lg", gap: "lg", display: "flex", borderRadius: "2xs", justify: "space-between", bg: MessageBackgroundColor[props.type], px: { xs: "lg", sm: "2xl", md: "2xl", lg: "2xl" }, direction: { xs: "column", sm: "row", md: "row", lg: "row" }, border: `${Spacing["4xs"]} solid ${MessageBorderColor[props.type]}` },
        React.createElement(FtsWrapper, { gap: "lg", display: "flex", align: "center", justify: "flex-start", flex: 1 },
            React.createElement(IconWrapper, { ...props },
                React.createElement(FtsIcons, { iconName: MessageIcon[props.type] })),
            React.createElement(FtsWrapper, { display: "flex", gap: "2xs", direction: "column", flex: 1 },
                props.title && (React.createElement(Span, { textAlign: "left", weight: WeightSize.semibold, variant: VariantsValues["text-2xs"], color: ColorsValue["greyscale-800"] }, props.title)),
                props.subtitle && (React.createElement(Span, { textAlign: "left", variant: VariantsValues["text-3xs"], color: ColorsValue["greyscale-800"] }, props.subtitle))),
            showCloseButton && (React.createElement(Button, { size: ButtonSizes.sm, onClick: props.onClose, type: ButtonTypes.text, iconName: IconNames.Close, color: ButtonColorsValue.neutral, display: { xs: "flex", sm: "none", md: "none", lg: "none" } }))),
        showButtons && (React.createElement(React.Fragment, null,
            React.createElement(MessageButtonsSm, { ...props, showCancelButton: showCancelButton, showConfirmButton: showConfirmButton }),
            React.createElement(FtsWrapper, { gap: "lg", align: "center", direction: "row", justify: "flex-end", display: { xs: "none", sm: "flex", md: "flex", lg: "flex" } },
                showCancelButton && (React.createElement(Button, { size: ButtonSizes.sm, onClick: props.onCancel, type: ButtonTypes.outlined, color: MessageButtonColor[props.type], label: props.cancelButtonLabel, iconName: IconNames.AlertOctagon })),
                showConfirmButton && (React.createElement(Button, { size: ButtonSizes.sm, onClick: props.onConfirm, type: ButtonTypes.contained, color: MessageButtonColor[props.type], label: props.confirmButtonLabel, iconName: IconNames.AlertOctagon })),
                showCloseButton && (React.createElement(Button, { size: ButtonSizes.sm, onClick: props.onClose, type: ButtonTypes.text, iconName: IconNames.Close, color: ButtonColorsValue.neutral })))))));
};
