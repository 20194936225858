import React from "react";
const PayPal = (props) => {
    return (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props.svgProps },
        React.createElement("g", { id: "Payments", "clip-path": "url(#clip0_3509_28702)" },
            React.createElement("g", { id: "Vector" },
                React.createElement("path", { d: "M9.82384 4.79925C9.65481 4.79938 9.49138 4.85827 9.36289 4.96532C9.23441 5.07238 9.1493 5.22058 9.12287 5.38331L7.95817 12.5834C8.01247 12.2472 8.30974 11.9994 8.65913 11.9994H12.0721C15.507 11.9994 18.4216 9.55701 18.9542 6.24724C18.9938 6.00014 19.0163 5.75071 19.0214 5.50065C18.1485 5.05452 17.1231 4.79925 15.9995 4.79925H9.82384Z", fill: "#001C64", ...props.pathProps }),
                React.createElement("path", { d: "M19.0214 5.50065C19.0163 5.75071 18.9938 6.00014 18.9542 6.24724C18.4216 9.55701 15.507 11.9994 12.0721 11.9994H8.65913C8.30974 11.9994 8.01247 12.2472 7.95817 12.5834L6.88724 19.2L6.21645 23.3507C6.20336 23.4309 6.20827 23.5129 6.23085 23.5911C6.25343 23.6693 6.29315 23.7417 6.34725 23.8035C6.40134 23.8652 6.46854 23.9148 6.54421 23.9488C6.61987 23.9828 6.7022 24.0003 6.78551 24.0003H10.49C10.659 24.0002 10.8224 23.9413 10.9509 23.8342C11.0794 23.7272 11.1645 23.579 11.1909 23.4162L12.1667 17.384C12.1931 17.2211 12.2783 17.0729 12.4069 16.9658C12.5355 16.8587 12.6991 16.7999 12.8682 16.7999H15.0492C18.4841 16.7999 21.3988 14.3575 21.9313 11.0478C22.3095 8.69869 21.0957 6.56079 19.0214 5.50065Z", fill: "#0070E0", ...props.pathProps }),
                React.createElement("path", { d: "M5.61508 0C5.26597 0 4.96843 0.247524 4.91413 0.583527L2.00715 18.5507C1.95203 18.8918 2.22244 19.2002 2.57676 19.2002L6.88724 19.2L7.95817 12.5834L9.12287 5.38331C9.1493 5.22058 9.23441 5.07238 9.36289 4.96532C9.49138 4.85827 9.65481 4.79938 9.82384 4.79925H15.9995C17.1231 4.79925 18.1485 5.05452 19.0214 5.50065C19.0812 2.48733 16.5299 0 13.0226 0H5.61508Z", fill: "#003087", ...props.pathProps }))),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_3509_28702" },
                React.createElement("rect", { width: "24", height: "24", fill: "white", ...props.pathProps })))));
};
export default PayPal;
