export const InputVariants = {
    contained: "contained",
    outlined: "outlined",
    text: "text",
};
export const InputSizes = {
    sm: "sm",
    md: "md",
};
export const InputStatuses = {
    default: "default",
    hover: "hover",
    focus: "focus",
};
