import { useDispatch, useSelector } from "react-redux";
import {
  selectServiceAmenities,
  selectServiceAmenitiesSearchValue,
} from "../../../../redux/reducers/essentialLists";
import { FtsRow } from "../../dist/ui/src/FtsRow/FtsRow";

import { FtsColumn } from "../../dist/ui/src/FtsColumn/FtsColumn";
import { P, Span } from "../../dist/ui/src/FtsText";

import Checkbox from "../../dist/ui/src/components/react/Checkbox";
import React, { useCallback, useEffect, useState } from "react";
import {
  selectListingAdsServiceAmenities,
  selectListingData,
} from "../../../../redux/reducers/v2/listing";
import { getListingDataByIdSuccess } from "../../../../redux/actions/v2/listings";
import { Input } from "../../dist/ui/src/components/react/Input";
import { InputSizes, InputVariants } from "../../dist/types/ui/input";
import { updateServicesAmenitiesSearchValue } from "../../../../redux/actions/essentialLists";
import { FtsWrapper } from "../../dist/ui/src/FtsWrapper/FtsWrapper";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import {
  ButtonColorsValue,
  ButtonSizes,
  ButtonTypes,
} from "../../dist/types/ui/button";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { ColorsValue } from "@find-truck-service/types/ui/colors";

export const ServiceAmenitiesPicker = () => {
  const dispatch = useDispatch();
  const serviceAmenitiesSearchValue = useSelector(
    selectServiceAmenitiesSearchValue,
  );
  const listingData = useSelector(selectListingData);
  const serviceAmenities = useSelector(selectServiceAmenities);
  const selectedServiceAmenities = useSelector(
    selectListingAdsServiceAmenities,
  );

  const [internalServiceAmenities, setInternalServiceAmenities] =
    useState(serviceAmenities);

  const handleSearch = useCallback(
    (event) => {
      let newServiceAmenities = [...serviceAmenities];

      newServiceAmenities = newServiceAmenities.filter((e) =>
        e.name.toLowerCase().includes(event.target.value.toLowerCase()),
      );

      dispatch(
        updateServicesAmenitiesSearchValue({
          serviceAmenitiesSearchValue: event.target.value,
        }),
      );
      setInternalServiceAmenities(newServiceAmenities);
    },
    [
      serviceAmenities,
      serviceAmenitiesSearchValue,
      setInternalServiceAmenities,
    ],
  );

  useEffect(() => {
    if (serviceAmenitiesSearchValue)
      handleSearch({ target: { value: serviceAmenitiesSearchValue } });
  }, [handleSearch]);

  const onChangeServiceAmenities = useCallback(
    (serviceAmenity) => {
      const isChecked = selectedServiceAmenities.find(
        (i) => i.id === serviceAmenity.id,
      );
      if (isChecked) {
        return dispatch(
          getListingDataByIdSuccess({
            data: {
              ...listingData,
              adsServiceAmenities: selectedServiceAmenities.filter(
                (e) => e.id !== serviceAmenity.id,
              ),
            },
          }),
        );
      }
      dispatch(
        getListingDataByIdSuccess({
          data: {
            ...listingData,
            adsServiceAmenities: [...selectedServiceAmenities, serviceAmenity],
          },
        }),
      );
    },
    [listingData],
  );

  return (
    <FtsRow columnGap={"lg"} rowGap={"lg"} mt={"2xl"}>
      <FtsColumn size={{ xs: 12 }}>
        <Span
          weight={WeightSize.medium}
          variant={VariantsValues["text-3xs"]}
          color={ColorsValue["blue-accent-500"]}
        >
          SERVICE AMENITIES
        </Span>
      </FtsColumn>{" "}
      <FtsColumn size={{ xs: 12 }}>
        <Input
          value={serviceAmenitiesSearchValue}
          onChange={handleSearch}
          placeHolder={"Search..."}
          icon={"Search"}
          variant={InputVariants.outlined}
          size={InputSizes.sm}
        />
      </FtsColumn>
      {internalServiceAmenities.length ? (
        internalServiceAmenities.map((e) => {
          return (
            <FtsColumn key={e.id} size={{ xs: 4 }}>
              <Checkbox
                onClick={() => onChangeServiceAmenities(e)}
                checked={!!selectedServiceAmenities.find((f) => f.id === e.id)}
                label={e.name}
              />
            </FtsColumn>
          );
        })
      ) : (
        <FtsColumn size={{ xs: 12 }}>
          <FtsWrapper display={"flex"} align={"center"} justify={"center"}>
            <FtsWrapper>
              <P
                variant={VariantsValues["text-2xs"]}
                color={ColorsValue["greyscale-800"]}
                mb={"sm"}
              >
                There is no service amenities
              </P>
              <Button
                onClick={() => handleSearch({ target: { value: "" } })}
                type={ButtonTypes.outlined}
                color={ButtonColorsValue.accent}
                size={ButtonSizes.sm}
                label={"Clear search"}
              />
            </FtsWrapper>
          </FtsWrapper>
        </FtsColumn>
      )}
    </FtsRow>
  );
};
