import { ButtonColorsValue, ButtonStatuses, ButtonTypes } from "@find-truck-service/types/ui/button";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
export const buttonLabelColors = {
    [ButtonColorsValue.accent]: {
        [ButtonTypes.contained]: {
            [ButtonStatuses.idle]: ColorsValue["greyscale-0"],
            [ButtonStatuses.hover]: ColorsValue["greyscale-0"],
            [ButtonStatuses.active]: ColorsValue["greyscale-0"],
            [ButtonStatuses.focus]: ColorsValue["greyscale-0"],
            [ButtonStatuses.disabled]: ColorsValue["greyscale-0"],
        },
        [ButtonTypes.outlined]: {
            [ButtonStatuses.idle]: ColorsValue["blue-accent-500"],
            [ButtonStatuses.hover]: ColorsValue["blue-accent-600"],
            [ButtonStatuses.active]: ColorsValue["blue-accent-700"],
            [ButtonStatuses.focus]: ColorsValue["blue-accent-500"],
            [ButtonStatuses.disabled]: ColorsValue["blue-accent-200"],
        },
        [ButtonTypes.text]: {
            [ButtonStatuses.idle]: ColorsValue["blue-accent-500"],
            [ButtonStatuses.hover]: ColorsValue["blue-accent-600"],
            [ButtonStatuses.active]: ColorsValue["blue-accent-700"],
            [ButtonStatuses.focus]: ColorsValue["blue-accent-500"],
            [ButtonStatuses.disabled]: ColorsValue["blue-accent-200"],
        },
    },
    [ButtonColorsValue.onBrand]: {
        [ButtonTypes.contained]: {
            [ButtonStatuses.idle]: ColorsValue["greyscale-800"],
            [ButtonStatuses.hover]: ColorsValue["greyscale-800"],
            [ButtonStatuses.active]: ColorsValue["greyscale-0"],
            [ButtonStatuses.focus]: ColorsValue["greyscale-800"],
            [ButtonStatuses.disabled]: ColorsValue["greyscale-0"],
        },
        [ButtonTypes.outlined]: {
            [ButtonStatuses.idle]: ColorsValue["greyscale-0"],
            [ButtonStatuses.hover]: ColorsValue["greyscale-0"],
            [ButtonStatuses.active]: ColorsValue["greyscale-0"],
            [ButtonStatuses.focus]: ColorsValue["greyscale-0"],
            [ButtonStatuses.disabled]: ColorsValue["red-brand-100"],
        },
        [ButtonTypes.text]: {
            [ButtonStatuses.idle]: ColorsValue["greyscale-0"],
            [ButtonStatuses.hover]: ColorsValue["greyscale-0"],
            [ButtonStatuses.active]: ColorsValue["greyscale-0"],
            [ButtonStatuses.focus]: ColorsValue["greyscale-0"],
            [ButtonStatuses.disabled]: ColorsValue["red-brand-100"],
        },
    },
    [ButtonColorsValue.error]: {
        [ButtonTypes.contained]: {
            [ButtonStatuses.idle]: ColorsValue["greyscale-0"],
            [ButtonStatuses.hover]: ColorsValue["greyscale-0"],
            [ButtonStatuses.active]: ColorsValue["greyscale-0"],
            [ButtonStatuses.focus]: ColorsValue["greyscale-0"],
            [ButtonStatuses.disabled]: ColorsValue["greyscale-0"],
        },
        [ButtonTypes.outlined]: {
            [ButtonStatuses.idle]: ColorsValue["red-error-500"],
            [ButtonStatuses.hover]: ColorsValue["red-error-600"],
            [ButtonStatuses.active]: ColorsValue["red-error-700"],
            [ButtonStatuses.focus]: ColorsValue["red-error-500"],
            [ButtonStatuses.disabled]: ColorsValue["red-error-200"],
        },
        [ButtonTypes.text]: {
            [ButtonStatuses.idle]: ColorsValue["red-error-500"],
            [ButtonStatuses.hover]: ColorsValue["red-error-600"],
            [ButtonStatuses.active]: ColorsValue["red-error-700"],
            [ButtonStatuses.focus]: ColorsValue["red-error-500"],
            [ButtonStatuses.disabled]: ColorsValue["red-error-200"],
        },
    },
    [ButtonColorsValue.success]: {
        [ButtonTypes.contained]: {
            [ButtonStatuses.idle]: ColorsValue["greyscale-0"],
            [ButtonStatuses.hover]: ColorsValue["greyscale-0"],
            [ButtonStatuses.active]: ColorsValue["greyscale-0"],
            [ButtonStatuses.focus]: ColorsValue["greyscale-0"],
            [ButtonStatuses.disabled]: ColorsValue["greyscale-0"],
        },
        [ButtonTypes.outlined]: {
            [ButtonStatuses.idle]: ColorsValue["green-success-500"],
            [ButtonStatuses.hover]: ColorsValue["green-success-600"],
            [ButtonStatuses.active]: ColorsValue["green-success-700"],
            [ButtonStatuses.focus]: ColorsValue["green-success-500"],
            [ButtonStatuses.disabled]: ColorsValue["green-success-200"],
        },
        [ButtonTypes.text]: {
            [ButtonStatuses.idle]: ColorsValue["green-success-500"],
            [ButtonStatuses.hover]: ColorsValue["green-success-600"],
            [ButtonStatuses.active]: ColorsValue["green-success-700"],
            [ButtonStatuses.focus]: ColorsValue["green-success-500"],
            [ButtonStatuses.disabled]: ColorsValue["green-success-200"],
        },
    },
    [ButtonColorsValue.warning]: {
        [ButtonTypes.contained]: {
            [ButtonStatuses.idle]: ColorsValue["greyscale-800"],
            [ButtonStatuses.hover]: ColorsValue["greyscale-800"],
            [ButtonStatuses.active]: ColorsValue["greyscale-800"],
            [ButtonStatuses.focus]: ColorsValue["greyscale-800"],
            [ButtonStatuses.disabled]: ColorsValue["greyscale-800"],
        },
        [ButtonTypes.outlined]: {
            [ButtonStatuses.idle]: ColorsValue["yellow-warning-800"],
            [ButtonStatuses.hover]: ColorsValue["yellow-warning-800"],
            [ButtonStatuses.active]: ColorsValue["yellow-warning-900"],
            [ButtonStatuses.focus]: ColorsValue["yellow-warning-800"],
            [ButtonStatuses.disabled]: ColorsValue["yellow-warning-500"],
        },
        [ButtonTypes.text]: {
            [ButtonStatuses.idle]: ColorsValue["yellow-warning-800"],
            [ButtonStatuses.hover]: ColorsValue["yellow-warning-800"],
            [ButtonStatuses.active]: ColorsValue["yellow-warning-900"],
            [ButtonStatuses.focus]: ColorsValue["yellow-warning-800"],
            [ButtonStatuses.disabled]: ColorsValue["yellow-warning-500"],
        },
    },
    [ButtonColorsValue.neutral]: {
        [ButtonTypes.contained]: {
            [ButtonStatuses.idle]: ColorsValue["greyscale-0"],
            [ButtonStatuses.hover]: ColorsValue["greyscale-0"],
            [ButtonStatuses.active]: ColorsValue["greyscale-0"],
            [ButtonStatuses.focus]: ColorsValue["greyscale-0"],
            [ButtonStatuses.disabled]: ColorsValue["greyscale-300"],
        },
        [ButtonTypes.outlined]: {
            [ButtonStatuses.idle]: ColorsValue["greyscale-700"],
            [ButtonStatuses.hover]: ColorsValue["greyscale-600"],
            [ButtonStatuses.active]: ColorsValue["greyscale-700"],
            [ButtonStatuses.focus]: ColorsValue["greyscale-700"],
            [ButtonStatuses.disabled]: ColorsValue["greyscale-300"],
        },
        [ButtonTypes.text]: {
            [ButtonStatuses.idle]: ColorsValue["greyscale-700"],
            [ButtonStatuses.hover]: ColorsValue["greyscale-600"],
            [ButtonStatuses.active]: ColorsValue["greyscale-700"],
            [ButtonStatuses.focus]: ColorsValue["greyscale-500"],
            [ButtonStatuses.disabled]: ColorsValue["greyscale-300"],
        },
    },
};
