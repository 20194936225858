import styled from "styled-components";
import { Spacing } from "@find-truck-service/types/ui/spacing";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { SvgIconSizeValues } from "@find-truck-service/types/ui/icons";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { InputSizes, InputVariants } from "@find-truck-service/types/ui/input";
const rightPosition = {
    [InputSizes.sm]: Spacing.sm,
    [InputSizes.md]: Spacing.md,
};
const containerSize = {
    [InputSizes.sm]: SvgIconSizeValues.md,
    [InputSizes.md]: SvgIconSizeValues.lg,
};
const svgProps = {
    [InputSizes.sm]: `svg { width:${SvgIconSizeValues.md}; height:${SvgIconSizeValues.md}; }`,
    [InputSizes.md]: `svg { width:${SvgIconSizeValues.lg}; height:${SvgIconSizeValues.lg}; }`,
};
const disabledStyle = `
  outline: none;
  cursor: not-allowed;
  pointer-events: none;
`;
const svgColor = ({ disabled }) => `
  path {
    stroke: ${ColorsValue[disabled ? "greyscale-300" : "greyscale-500"]};
  }
`;
export const AppendIcon = styled(FtsWrapper).withConfig({
    shouldForwardProp: (prop) => !["size", "variant", "disabled"].includes(prop),
}) `
  ${svgColor};
  position: absolute;
  ${(props) => svgProps[props.size]}
  width: ${(props) => containerSize[props.size]};
  height: ${(props) => containerSize[props.size]};
  right: ${({ variant, size }) => (variant !== InputVariants.text ? rightPosition[size] : 0)};
  cursor: ${(props) => (props.onClick ? "pointer" : "default")};
  &:focus {
    border-radius: ${Spacing["2xs"]};
    outline: ${Spacing["3xs"]} solid ${ColorsValue["blue-accent-300"]};
  }
  ${({ disabled }) => (disabled ? disabledStyle : {})}
`;
