import React from "react";
import { Span } from "../../../FtsText";
import FtsIcons from "../../../FtsIcons/FtsIcons";
import { FtsChips } from "../../../FtsChips/FtsChips";
import { ChipGapBySizesValues, ChipLabelSizeBySize } from "./Types";
import { LineHeightVariantsValues } from "@find-truck-service/types/ui/text";
export const Chips = (props) => {
    const { labelProps, label, appendIcon, prependIcon, size } = props;
    return (React.createElement(FtsChips, { gap: ChipGapBySizesValues[size], ...props },
        prependIcon && React.createElement(FtsIcons, { ...prependIcon }),
        React.createElement(Span, { display: "flex", variant: ChipLabelSizeBySize[size], lineHeightVariant: LineHeightVariantsValues.relaxed, ...labelProps }, label),
        appendIcon && React.createElement(FtsIcons, { ...appendIcon })));
};
