import { Spacing } from "@find-truck-service/types/ui/spacing";
import { ShadowsValue } from "@find-truck-service/types/ui/shadows";
import { CursorsValue } from "@find-truck-service/types/ui/cursors";
import { device } from "@find-truck-service/types/ui/breakpoints";
const paddingProps = ["p", "pt", "pb", "pl", "pr", "px", "py"];
const marginProps = ["m", "mt", "mb", "ml", "mr", "mx", "my"];
export const defaultProps = [
    "bg",
    "display",
    "align",
    "justify",
    "width",
    "height",
    "maxHeight",
    "textAlign",
    "border",
    "outline",
    "borderBottom",
    "borderTop",
    "cursor",
    "direction",
    "wrap",
    "textDecoration",
    "gap",
    "position",
    "opacity",
    "top",
    "left",
    "right",
    "bottom",
    "zIndex",
    "flex",
    "bgImage",
    "order",
    "overflowWrap",
    "overflow",
    "whitespace",
    "rowGap",
    "columnGap",
    "minWidth",
    "borderRadius",
    "boxShadow",
    "maxWidth",
    ...paddingProps,
    ...marginProps,
];
export const defaultStyleProps = (props) => {
    let res = ``;
    if (props.m) {
        if (typeof props.m === "string") {
            res += `margin: ${Spacing[props.m]};`;
        }
        else {
            for (let [key, val] of Object.entries(device)) {
                let m = props.m[key];
                if (m !== undefined) {
                    res += `@media ${val} { margin: ${Spacing[m]}; };`;
                }
            }
        }
    }
    if (props.mt) {
        if (typeof props.mt === "string") {
            res += `margin-top: ${Spacing[props.mt]};`;
        }
        else {
            for (let [key, val] of Object.entries(device)) {
                let mt = props.mt[key];
                if (mt !== undefined) {
                    res += `@media ${val} { margin-top: ${Spacing[mt]}; };`;
                }
            }
        }
    }
    if (props.mb) {
        if (typeof props.mb === "string") {
            res += `margin-bottom: ${Spacing[props.mb]};`;
        }
        else {
            for (let [key, val] of Object.entries(device)) {
                let mb = props.mb[key];
                if (mb !== undefined) {
                    res += `@media ${val} { margin-bottom: ${Spacing[mb]}; };`;
                }
            }
        }
    }
    if (props.ml) {
        if (typeof props.ml === "string") {
            res += `margin-left: ${Spacing[props.ml]};`;
        }
        else {
            for (let [key, val] of Object.entries(device)) {
                let ml = props.ml[key];
                if (ml !== undefined) {
                    res += `@media ${val} { margin-left: ${Spacing[ml]}; };`;
                }
            }
        }
    }
    if (props.mr) {
        if (typeof props.mr === "string") {
            res += `margin-right: ${Spacing[props.mr]};`;
        }
        else {
            for (let [key, val] of Object.entries(device)) {
                let mr = props.mr[key];
                if (mr !== undefined) {
                    res += `@media ${val} { margin-right: ${Spacing[mr]}; };`;
                }
            }
        }
    }
    if (props.mx) {
        if (typeof props.mx === "string") {
            res += `margin-left: ${Spacing[props.mx]}; margin-right: ${Spacing[props.mx]};`;
        }
        else {
            for (let [key, val] of Object.entries(device)) {
                let mx = props.mx[key];
                if (mx !== undefined) {
                    res += `@media ${val} {  margin-left: ${Spacing[mx]}; margin-right: ${Spacing[mx]}; };`;
                }
            }
        }
    }
    if (props.my) {
        if (typeof props.my === "string") {
            res += `margin-top: ${Spacing[props.my]}; margin-bottom: ${Spacing[props.my]};`;
        }
        else {
            for (let [key, val] of Object.entries(device)) {
                let my = props.my[key];
                if (my !== undefined) {
                    res += `@media ${val} {  margin-top: ${Spacing[my]}; margin-bottom: ${Spacing[my]}; };`;
                }
            }
        }
    }
    if (props.p) {
        if (typeof props.p === "string") {
            res += `padding: ${Spacing[props.p]};`;
        }
        else {
            for (let [key, val] of Object.entries(device)) {
                let p = props.p[key];
                if (p !== undefined) {
                    res += `@media ${val} { padding: ${Spacing[p]}; };`;
                }
            }
        }
    }
    if (props.pt) {
        if (typeof props.pt === "string") {
            res += `padding-top: ${Spacing[props.pt]};`;
        }
        else {
            for (let [key, val] of Object.entries(device)) {
                let pt = props.pt[key];
                if (pt !== undefined) {
                    res += `@media ${val} { padding-top: ${Spacing[pt]}; };`;
                }
            }
        }
    }
    if (props.pb) {
        if (typeof props.pb === "string") {
            res += `padding-bottom: ${Spacing[props.pb]};`;
        }
        else {
            for (let [key, val] of Object.entries(device)) {
                let pb = props.pb[key];
                if (pb !== undefined) {
                    res += `@media ${val} { padding-bottom: ${Spacing[pb]}; };`;
                }
            }
        }
    }
    if (props.pl) {
        if (typeof props.pl === "string") {
            res += `padding-left: ${Spacing[props.pl]};`;
        }
        else {
            for (let [key, val] of Object.entries(device)) {
                let pl = props.pl[key];
                if (pl !== undefined) {
                    res += `@media ${val} { padding-left: ${Spacing[pl]}; };`;
                }
            }
        }
    }
    if (props.pr) {
        if (typeof props.pr === "string") {
            res += `padding-right: ${Spacing[props.pr]};`;
        }
        else {
            for (let [key, val] of Object.entries(device)) {
                let pr = props.pr[key];
                if (pr !== undefined) {
                    res += `@media ${val} { padding-right: ${Spacing[pr]}; };`;
                }
            }
        }
    }
    if (props.px) {
        if (typeof props.px === "string") {
            res += `padding-left: ${Spacing[props.px]}; padding-right: ${Spacing[props.px]};`;
        }
        else {
            for (let [key, val] of Object.entries(device)) {
                let px = props.px[key];
                if (px !== undefined) {
                    res += `@media ${val} {  padding-left: ${Spacing[px]}; padding-right: ${Spacing[px]}; };`;
                }
            }
        }
    }
    if (props.py) {
        if (typeof props.py === "string") {
            res += `padding-top: ${Spacing[props.py]}; padding-bottom: ${Spacing[props.py]};`;
        }
        else {
            for (let [key, val] of Object.entries(device)) {
                let py = props.py[key];
                if (py !== undefined) {
                    res += `@media ${val} { padding-top: ${Spacing[py]}; padding-bottom: ${Spacing[py]}; };`;
                }
            }
        }
    }
    if (props.borderRadius) {
        res += `border-radius: ${Spacing[props.borderRadius]};`;
    }
    if (props.bg) {
        res += `background: ${props.bg};`;
    }
    if (props.bgImage) {
        if (typeof props.bgImage === "string") {
            res += `background-image: ${props.bgImage};`;
        }
        else {
            for (let [key, val] of Object.entries(device)) {
                let bgImage = props.bgImage[key];
                if (bgImage !== undefined) {
                    res += `@media ${val} { background-image: ${bgImage}; };`;
                }
            }
        }
    }
    if (props.display) {
        if (typeof props.display === "string") {
            res += `display: ${props.display};`;
        }
        else {
            for (let [key, val] of Object.entries(device)) {
                let display = props.display[key];
                if (display !== undefined) {
                    res += `@media ${val} { display: ${display}; };`;
                }
            }
        }
    }
    if (props.align) {
        if (typeof props.align === "string") {
            res += `align-items: ${props.align};`;
        }
        else {
            for (let [key, val] of Object.entries(device)) {
                let align = props.align[key];
                if (align !== undefined) {
                    res += `@media ${val} { align-items: ${align}; };`;
                }
            }
        }
    }
    if (props.justify) {
        if (typeof props.justify === "string") {
            res += `justify-content: ${props.justify};`;
        }
        else {
            for (let [key, val] of Object.entries(device)) {
                let justify = props.justify[key];
                if (justify !== undefined) {
                    res += `@media ${val} { justify-content: ${justify}; };`;
                }
            }
        }
    }
    if (props.direction) {
        if (typeof props.direction === "string") {
            res += `flex-direction: ${props.direction};`;
        }
        else {
            for (let [key, val] of Object.entries(device)) {
                let direction = props.direction[key];
                if (direction !== undefined) {
                    res += `@media ${val} { flex-direction: ${direction}; };`;
                }
            }
        }
    }
    if (props.order) {
        if (typeof props.order === "string") {
            res += `order: ${props.order};`;
        }
        else {
            for (let [key, val] of Object.entries(device)) {
                let order = props.order[key];
                if (order !== undefined) {
                    res += `@media ${val} { order: ${order}; };`;
                }
            }
        }
    }
    if (props.wrap) {
        res += `flex-wrap: ${props.wrap};`;
    }
    if (props.width) {
        if (typeof props.width === "string") {
            res += `width: ${props.width};`;
        }
        else {
            for (let [key, val] of Object.entries(device)) {
                let width = props.width[key];
                if (width !== undefined) {
                    res += `@media ${val} { width: ${width}; };`;
                }
            }
        }
    }
    if (props.height) {
        if (typeof props.height === "string") {
            res += `height: ${props.height};`;
        }
        else {
            for (let [key, val] of Object.entries(device)) {
                let height = props.height[key];
                if (height !== undefined) {
                    res += `@media ${val} { height: ${height}; };`;
                }
            }
        }
    }
    if (props.maxWidth) {
        if (typeof props.maxWidth === "string") {
            res += `max-width: ${props.maxWidth};`;
        }
        else {
            for (let [key, val] of Object.entries(device)) {
                let maxWidth = props.maxWidth[key];
                if (maxWidth !== undefined) {
                    res += `@media ${val} { max-width: ${maxWidth}; };`;
                }
            }
        }
    }
    if (props.maxHeight) {
        res += `max-height: ${props.maxHeight};`;
    }
    if (props.overflow) {
        res += `overflow: ${props.overflow};`;
    }
    if (props.textAlign) {
        if (typeof props.textAlign === "string") {
            res += `text-align: ${props.textAlign};`;
        }
        else {
            for (let [key, val] of Object.entries(device)) {
                let textAlign = props.textAlign[key];
                if (textAlign !== undefined) {
                    res += `@media ${val} { text-align: ${textAlign}; };`;
                }
            }
        }
    }
    if (props.border) {
        res += `border: ${props.border};`;
    }
    if (props.outline) {
        res += `outline: ${props.outline};`;
    }
    if (props.borderBottom) {
        res += `border-bottom: ${props.borderBottom};`;
    }
    if (props.borderTop) {
        res += `border-top: ${props.borderTop};`;
    }
    if (props.boxShadow) {
        res += `box-shadow: ${ShadowsValue[props.boxShadow]};`;
    }
    if (props.wrap) {
        res += `flex-wrap: ${props.wrap};`;
    }
    if (props.textDecoration) {
        res += `text-decoration: ${props.textDecoration};`;
    }
    if (props.overflowWrap) {
        res += `overflow-wrap: ${props.overflowWrap};`;
    }
    if (props.cursor) {
        res += `cursor: ${CursorsValue[props.cursor]};`;
    }
    if (props.position) {
        if (typeof props.position === "string") {
            res += `position: ${props.position};`;
        }
        else {
            for (let [key, val] of Object.entries(device)) {
                let position = props.position[key];
                if (position !== undefined) {
                    res += `@media ${val} { position: ${position}; };`;
                }
            }
        }
        // res += `position: ${props.position};`;
    }
    if (props.opacity) {
        res += `opacity: ${props.opacity};`;
    }
    if (props.top) {
        res += `top: ${props.top};`;
    }
    if (props.left) {
        res += `left: ${props.left};`;
    }
    if (props.right) {
        res += `right: ${props.right};`;
    }
    if (props.bottom) {
        res += `bottom: ${props.bottom};`;
    }
    if (props.gap) {
        if (typeof props.gap === "string") {
            res += `gap: ${Spacing[props.gap]};`;
        }
        else {
            for (let [key, val] of Object.entries(device)) {
                let gap = props.gap[key];
                if (gap !== undefined) {
                    res += `@media ${val} { gap: ${Spacing[gap]}; };`;
                }
            }
        }
    }
    if (props.zIndex) {
        res += `z-index: ${props.zIndex};`;
    }
    if (props.flex) {
        res += `flex: ${props.flex};`;
    }
    if (props.whitespace) {
        res += `white-space: ${props.whitespace};`;
    }
    if (props.rowGap) {
        if (typeof props.rowGap === "string") {
            res += `row-gap: ${Spacing[props.rowGap]};`;
        }
        else {
            for (let [key, val] of Object.entries(device)) {
                let rowGap = props.rowGap[key];
                if (rowGap !== undefined) {
                    res += `@media ${val} { row-gap: ${Spacing[rowGap]}; };`;
                }
            }
        }
    }
    if (props.columnGap) {
        if (typeof props.columnGap === "string") {
            res += `column-gap: ${Spacing[props.columnGap]};`;
        }
        else {
            for (let [key, val] of Object.entries(device)) {
                let columnGap = props.columnGap[key];
                if (columnGap !== undefined) {
                    res += `@media ${val} { column-gap: ${Spacing[columnGap]}; };`;
                }
            }
        }
    }
    if (props.minWidth) {
        if (typeof props.minWidth === "string") {
            res += `min-width: ${props.minWidth};`;
        }
        else {
            for (let [key, val] of Object.entries(device)) {
                let minWidth = props.minWidth[key];
                if (minWidth !== undefined) {
                    res += `@media ${val} { min-width: ${minWidth}; };`;
                }
            }
        }
    }
    return res;
};
