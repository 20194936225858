import styled from "styled-components";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { Span, TextStyle } from "@find-truck-service/ui/src/FtsText";
import { InputSizes } from "@find-truck-service/types/ui/input";
import { LineHeightVariantsValues, VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
const helperTextVariant = {
    [InputSizes.sm]: VariantsValues["text-4xs"],
    [InputSizes.md]: VariantsValues["text-3xs"],
};
const disabledLink = `
  color: ${ColorsValue["greyscale-300"]} !important;
  text-decoration: none !important;
  outline: none !important;
  pointer-events: none;
  cursor: default;
`;
const helperTextColor = (props) => {
    if (props.disabled)
        return `color: ${ColorsValue["greyscale-300"]}; a { ${disabledLink} }`;
    if (props.errorMessage)
        return `color: ${ColorsValue["red-error-500"]};`;
    return `color: ${ColorsValue["greyscale-500"]};`;
};
const labelTextStyle = (props) => `
  ${TextStyle({
    weight: WeightSize.regular,
    variant: helperTextVariant[props.size],
    lineHeightVariant: LineHeightVariantsValues.tight,
})}`;
export const InputHelperText = styled(Span).withConfig({
    shouldForwardProp: (prop) => !["errorMessage", "disabled", "size"].includes(prop),
}) `
  ${labelTextStyle}
  ${helperTextColor}
`;
