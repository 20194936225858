import { ButtonColorsValue, ButtonStatuses, ButtonTypes } from "@find-truck-service/types/ui/button";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
export const buttonStrokeColors = {
    [ButtonColorsValue.accent]: {
        [ButtonTypes.contained]: {
            [ButtonStatuses.idle]: ColorsValue["blue-accent-500"],
            [ButtonStatuses.hover]: ColorsValue["blue-accent-600"],
            [ButtonStatuses.active]: ColorsValue["blue-accent-700"],
            [ButtonStatuses.focus]: ColorsValue["blue-accent-300"],
            [ButtonStatuses.disabled]: ColorsValue["blue-accent-200"],
        },
        [ButtonTypes.outlined]: {
            [ButtonStatuses.idle]: ColorsValue["blue-accent-300"],
            [ButtonStatuses.hover]: ColorsValue["blue-accent-400"],
            [ButtonStatuses.active]: ColorsValue["blue-accent-700"],
            [ButtonStatuses.focus]: ColorsValue["blue-accent-300"],
            [ButtonStatuses.disabled]: ColorsValue["greyscale-50"],
        },
        [ButtonTypes.text]: {
            [ButtonStatuses.idle]: ColorsValue["transparent"],
            [ButtonStatuses.hover]: ColorsValue["blue-accent-50"],
            [ButtonStatuses.active]: ColorsValue["blue-accent-700"],
            [ButtonStatuses.focus]: ColorsValue["blue-accent-300"],
            [ButtonStatuses.disabled]: ColorsValue["transparent"],
        },
    },
    [ButtonColorsValue.onBrand]: {
        [ButtonTypes.contained]: {
            [ButtonStatuses.idle]: ColorsValue["greyscale-0"],
            [ButtonStatuses.hover]: ColorsValue["greyscale-50"],
            [ButtonStatuses.active]: ColorsValue["red-brand-700"],
            [ButtonStatuses.focus]: ColorsValue["red-brand-200"],
            [ButtonStatuses.disabled]: ColorsValue["red-brand-200"],
        },
        [ButtonTypes.outlined]: {
            [ButtonStatuses.idle]: ColorsValue["greyscale-0"],
            [ButtonStatuses.hover]: ColorsValue["greyscale-0"],
            [ButtonStatuses.active]: ColorsValue["red-brand-700"],
            [ButtonStatuses.focus]: ColorsValue["red-brand-200"],
            [ButtonStatuses.disabled]: ColorsValue["red-brand-300"],
        },
        [ButtonTypes.text]: {
            [ButtonStatuses.idle]: ColorsValue["transparent"],
            [ButtonStatuses.hover]: ColorsValue["red-brand-600"],
            [ButtonStatuses.active]: ColorsValue["red-brand-700"],
            [ButtonStatuses.focus]: ColorsValue["red-brand-200"],
            [ButtonStatuses.disabled]: ColorsValue["transparent"],
        },
    },
    [ButtonColorsValue.error]: {
        [ButtonTypes.contained]: {
            [ButtonStatuses.idle]: ColorsValue["red-error-500"],
            [ButtonStatuses.hover]: ColorsValue["red-error-600"],
            [ButtonStatuses.active]: ColorsValue["red-error-700"],
            [ButtonStatuses.focus]: ColorsValue["red-error-300"],
            [ButtonStatuses.disabled]: ColorsValue["red-error-200"],
        },
        [ButtonTypes.outlined]: {
            [ButtonStatuses.idle]: ColorsValue["red-error-300"],
            [ButtonStatuses.hover]: ColorsValue["red-error-400"],
            [ButtonStatuses.active]: ColorsValue["red-error-700"],
            [ButtonStatuses.focus]: ColorsValue["red-error-300"],
            [ButtonStatuses.disabled]: ColorsValue["red-error-50"],
        },
        [ButtonTypes.text]: {
            [ButtonStatuses.idle]: ColorsValue["transparent"],
            [ButtonStatuses.hover]: ColorsValue["red-error-50"],
            [ButtonStatuses.active]: ColorsValue["red-error-700"],
            [ButtonStatuses.focus]: ColorsValue["red-error-300"],
            [ButtonStatuses.disabled]: ColorsValue["transparent"],
        },
    },
    [ButtonColorsValue.success]: {
        [ButtonTypes.contained]: {
            [ButtonStatuses.idle]: ColorsValue["green-success-500"],
            [ButtonStatuses.hover]: ColorsValue["green-success-600"],
            [ButtonStatuses.active]: ColorsValue["green-success-700"],
            [ButtonStatuses.focus]: ColorsValue["green-success-300"],
            [ButtonStatuses.disabled]: ColorsValue["green-success-200"],
        },
        [ButtonTypes.outlined]: {
            [ButtonStatuses.idle]: ColorsValue["green-success-300"],
            [ButtonStatuses.hover]: ColorsValue["green-success-400"],
            [ButtonStatuses.active]: ColorsValue["green-success-700"],
            [ButtonStatuses.focus]: ColorsValue["green-success-300"],
            [ButtonStatuses.disabled]: ColorsValue["green-success-50"],
        },
        [ButtonTypes.text]: {
            [ButtonStatuses.idle]: ColorsValue["transparent"],
            [ButtonStatuses.hover]: ColorsValue["green-success-50"],
            [ButtonStatuses.active]: ColorsValue["green-success-700"],
            [ButtonStatuses.focus]: ColorsValue["green-success-300"],
            [ButtonStatuses.disabled]: ColorsValue["transparent"],
        },
    },
    [ButtonColorsValue.warning]: {
        [ButtonTypes.contained]: {
            [ButtonStatuses.idle]: ColorsValue["yellow-warning-400"],
            [ButtonStatuses.hover]: ColorsValue["yellow-warning-500"],
            [ButtonStatuses.active]: ColorsValue["yellow-warning-600"],
            [ButtonStatuses.focus]: ColorsValue["yellow-warning-300"],
            [ButtonStatuses.disabled]: ColorsValue["yellow-warning-200"],
        },
        [ButtonTypes.outlined]: {
            [ButtonStatuses.idle]: ColorsValue["yellow-warning-300"],
            [ButtonStatuses.hover]: ColorsValue["yellow-warning-400"],
            [ButtonStatuses.active]: ColorsValue["yellow-warning-700"],
            [ButtonStatuses.focus]: ColorsValue["yellow-warning-300"],
            [ButtonStatuses.disabled]: ColorsValue["yellow-warning-50"],
        },
        [ButtonTypes.text]: {
            [ButtonStatuses.idle]: ColorsValue["transparent"],
            [ButtonStatuses.hover]: ColorsValue["yellow-warning-50"],
            [ButtonStatuses.active]: ColorsValue["yellow-warning-700"],
            [ButtonStatuses.focus]: ColorsValue["yellow-warning-300"],
            [ButtonStatuses.disabled]: ColorsValue["transparent"],
        },
    },
    [ButtonColorsValue.neutral]: {
        [ButtonTypes.contained]: {
            [ButtonStatuses.idle]: ColorsValue["greyscale-500"],
            [ButtonStatuses.hover]: ColorsValue["greyscale-600"],
            [ButtonStatuses.active]: ColorsValue["greyscale-700"],
            [ButtonStatuses.focus]: ColorsValue["greyscale-100"],
            [ButtonStatuses.disabled]: ColorsValue["greyscale-50"],
        },
        [ButtonTypes.outlined]: {
            [ButtonStatuses.idle]: ColorsValue["greyscale-100"],
            [ButtonStatuses.hover]: ColorsValue["greyscale-200"],
            [ButtonStatuses.active]: ColorsValue["greyscale-300"],
            [ButtonStatuses.focus]: ColorsValue["greyscale-100"],
            [ButtonStatuses.disabled]: ColorsValue["greyscale-50"],
        },
        [ButtonTypes.text]: {
            [ButtonStatuses.idle]: ColorsValue["transparent"],
            [ButtonStatuses.hover]: ColorsValue["greyscale-50"],
            [ButtonStatuses.active]: ColorsValue["greyscale-300"],
            [ButtonStatuses.focus]: ColorsValue["greyscale-100"],
            [ButtonStatuses.disabled]: ColorsValue["transparent"],
        },
    },
};
