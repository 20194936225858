import React, { useCallback } from "react";
import { Span } from "../../dist/ui/src/FtsText";
import { useDispatch, useSelector } from "react-redux";
import { FtsRow } from "../../dist/ui/src/FtsRow/FtsRow";
import { Input } from "../../dist/ui/src/components/react/Input";
import { FtsColumn } from "../../dist/ui/src/FtsColumn/FtsColumn";
import { Dropdown } from "../../dist/ui/src/components/react/Dropdown";
import { selectStates } from "../../../../redux/reducers/essentialLists";
import { setAccountDataAction } from "../../../../redux/actions/v2/account";
import { InputSizes, InputVariants } from "../../dist/types/ui/input";
import {
  selectAccountData,
  selectAccountInvalidFormFields,
} from "../../../../redux/reducers/v2/account";
import isObject from "lodash/isObject";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { ColorsValue } from "@find-truck-service/types/ui/colors";

export const AccountCompanyRow = () => {
  const accountData = useSelector(selectAccountData);
  const states = useSelector(selectStates);
  const dispatch = useDispatch();
  const invalidFormFields = useSelector(selectAccountInvalidFormFields);

  const onChange = useCallback(
    (event) => {
      dispatch(
        setAccountDataAction({
          ...accountData,
          [event.target.name]: event.target.value,
        }),
      );
    },
    [accountData],
  );

  return (
    <FtsRow columnGap={"lg"} rowGap={"lg"} py={"2xl"}>
      <FtsColumn size={{ xs: 12 }}>
        <Span
          weight={WeightSize.medium}
          variant={VariantsValues["text-3xs"]}
          color={ColorsValue["blue-accent-500"]}
        >
          COMPANY
        </Span>
      </FtsColumn>
      <FtsColumn size={{ xs: 12 }}>
        <Input
          name={"companyName"}
          value={accountData.companyName}
          onChange={onChange}
          label={"Company name"}
          required
          variant={InputVariants.outlined}
          size={InputSizes.sm}
          errorMessage={
            isObject(invalidFormFields) && invalidFormFields["companyName"]
          }
        />
      </FtsColumn>

      <FtsColumn size={{ xs: 6 }}>
        <Input
          name={"address"}
          value={accountData.address}
          onChange={onChange}
          errorMessage={
            isObject(invalidFormFields) && invalidFormFields["address"]
          }
          label={"Street"}
          required
          variant={InputVariants.outlined}
          size={InputSizes.sm}
        />
      </FtsColumn>
      <FtsColumn size={{ xs: 6 }}>
        <FtsRow columnGap={"lg"} rowGap={"lg"}>
          <FtsColumn size={{ xs: 6 }}>
            <Input
              errorMessage={
                isObject(invalidFormFields) && invalidFormFields["city"]
              }
              name={"city"}
              value={accountData.city}
              onChange={onChange}
              label={"City"}
              required
              variant={InputVariants.outlined}
              size={InputSizes.sm}
            />
          </FtsColumn>
          <FtsColumn size={{ xs: 3 }}>
            <Dropdown
              size={"sm"}
              errorMessage={
                isObject(invalidFormFields) && invalidFormFields["state"]
              }
              options={states}
              label={"State"}
              required
              name={"state"}
              value={states.find((e) => e.value === accountData.state)}
              onChange={(val) =>
                onChange({ target: { name: "state", value: val?.label || "" } })
              }
            />
          </FtsColumn>
          <FtsColumn size={{ xs: 3 }}>
            <Input
              errorMessage={
                isObject(invalidFormFields) && invalidFormFields["zip"]
              }
              name={"zip"}
              value={accountData.zip}
              onChange={onChange}
              pattern="\d*"
              maxLength="5"
              label={"Zip code"}
              required
              variant={InputVariants.outlined}
              size={InputSizes.sm}
            />
          </FtsColumn>
        </FtsRow>
      </FtsColumn>
      <FtsColumn size={{ xs: 6 }}>
        <Input
          name={"website"}
          value={accountData.website}
          onChange={onChange}
          label={"Website"}
          variant={InputVariants.outlined}
          size={InputSizes.sm}
          errorMessage={
            isObject(invalidFormFields) && invalidFormFields["website"]
          }
        />
      </FtsColumn>
      <FtsColumn size={{ xs: 6 }}>
        <Input
          name={"website2"}
          value={accountData.website2}
          onChange={onChange}
          label={"Website 2"}
          variant={InputVariants.outlined}
          size={InputSizes.sm}
          errorMessage={
            isObject(invalidFormFields) && invalidFormFields["website2"]
          }
        />
      </FtsColumn>
    </FtsRow>
  );
};
