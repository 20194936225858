import styled from "styled-components";
import { Spacing } from "@find-truck-service/types/ui/spacing";
import { SvgIconSizeValues } from "@find-truck-service/types/ui/icons";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { InputSizes, InputVariants } from "@find-truck-service/types/ui/input";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
const leftPosition = {
    [InputSizes.sm]: Spacing.sm,
    [InputSizes.md]: Spacing.md,
};
const containerSize = {
    [InputSizes.sm]: SvgIconSizeValues.md,
    [InputSizes.md]: SvgIconSizeValues.lg,
};
const svgProps = {
    [InputSizes.sm]: `svg { width:${SvgIconSizeValues.md}; height:${SvgIconSizeValues.md}; }`,
    [InputSizes.md]: `svg { width:${SvgIconSizeValues.lg}; height:${SvgIconSizeValues.lg}; }`,
};
const svgColor = ({ disabled }) => `
  path {
    stroke: ${ColorsValue[disabled ? "greyscale-300" : "greyscale-500"]};
  }
`;
export const PrependIcon = styled(FtsWrapper).withConfig({
    shouldForwardProp: (prop) => !["size", "variant", "disabled"].includes(prop),
}) `
  ${svgColor};
  position: absolute;
  ${(props) => svgProps[props.size]}
  width: ${(props) => containerSize[props.size]};
  height: ${(props) => containerSize[props.size]};
  left: ${({ variant, size }) => (variant !== InputVariants.text ? leftPosition[size] : 0)};
`;
