import styled from "styled-components";
import { Spacing } from "@find-truck-service/types/ui/spacing";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
export const FtsAccordionContainer = styled(FtsWrapper) `
  height: fit-content;
  border-style: solid;
  border-width: ${Spacing["4xs"]};
  border-radius: ${Spacing["2xs"]};
  border-color: ${(props) => (props.disabled ? ColorsValue["greyscale-50"] : ColorsValue["greyscale-100"])};
`;
