import React from "react";
import { Input } from "../Input";
import Checkbox from "../Checkbox";
import { Span } from "../../../FtsText";
import { FtsRow } from "../../../FtsRow/FtsRow";
import { RichTextEditor } from "../RichTextEditor";
import { PhoneNumberInput } from "../PhoneNumberInput";
import { getErrorMsg } from "../CreditCardForm/helpers";
import { FtsColumn } from "../../../FtsColumn/FtsColumn";
import { Spacing } from "@find-truck-service/types/ui/spacing";
import { FtsWrapper } from "../../../FtsWrapper/FtsWrapper";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { checkboxOptLabels, mobileOptIn, primaryOptIn } from "./constants";
import { InputSizes, InputVariants } from "@find-truck-service/types/ui/input";
import { ButtonColorsValue, ButtonSizes, ButtonTypes } from "@find-truck-service/types/ui/button";
import { VariantsValues } from "@find-truck-service/types/ui/text";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
export const AltContactForm = (props) => {
    const { title, onSubmit, handleOnChange, onRemoveAltContact, closeAltContactForm, submitButtonTitle, isEdit, altContactData, invalidFormFields, handleCheckboxChange, } = props;
    return (React.createElement(FtsColumn, { size: { xs: 12 }, borderRadius: "2xs", py: "lg", px: "2xl", border: `${Spacing["4xs"]} solid ${ColorsValue["blue-accent-300"]}` },
        React.createElement(FtsRow, { columnGap: "lg", rowGap: "lg" },
            React.createElement(FtsColumn, { size: { xs: 12 } },
                React.createElement(Span, { variant: VariantsValues["text-2xs"], color: ColorsValue["greyscale-800"] }, title)),
            React.createElement(FtsColumn, { size: { xs: 4 } },
                React.createElement(Input, { required: true, label: "First name", size: InputSizes.sm, name: "altContactFirstName", variant: InputVariants.outlined, value: altContactData.altContactFirstName, errorMessage: getErrorMsg(invalidFormFields, "altContactFirstName"), onChange: (e) => handleOnChange(e, "altContactFirstName") })),
            React.createElement(FtsColumn, { size: { xs: 4 } },
                React.createElement(Input, { required: true, label: "Last name", size: InputSizes.sm, name: "altContactLastName", variant: InputVariants.outlined, value: altContactData.altContactLastName, errorMessage: getErrorMsg(invalidFormFields, "altContactLastName"), onChange: (e) => handleOnChange(e, "altContactLastName") })),
            React.createElement(FtsColumn, { size: { xs: 4 } },
                React.createElement(Input, { size: InputSizes.sm, label: "Title/Position", name: "altContactJobTitle", variant: InputVariants.outlined, value: altContactData.altContactJobTitle, onChange: (e) => handleOnChange(e, "altContactJobTitle") })),
            React.createElement(FtsColumn, { size: { xs: 6 } },
                React.createElement(PhoneNumberInput, { size: InputSizes.sm, label: "Phone (primary)", name: "altContactPhoneNumber", variant: InputVariants.outlined, value: altContactData.altContactPhoneNumber, onChange: (e) => handleOnChange(e, "altContactPhoneNumber"), errorMessage: getErrorMsg(invalidFormFields, "altContactPhoneNumber") })),
            React.createElement(FtsColumn, { size: { xs: 6 } },
                React.createElement(PhoneNumberInput, { size: InputSizes.sm, label: "Phone (secondary)", variant: InputVariants.outlined, name: "altContactPhoneNumberSecondary", value: altContactData.altContactPhoneNumberSecondary, onChange: (e) => handleOnChange(e, "altContactPhoneNumberSecondary"), errorMessage: getErrorMsg(invalidFormFields, "altContactPhoneNumberSecondary") })),
            React.createElement(FtsColumn, { size: { xs: 6 } },
                React.createElement(PhoneNumberInput, { label: "Mobile", size: InputSizes.sm, name: "altContactMobileNumber", variant: InputVariants.outlined, value: altContactData.altContactMobileNumber, onChange: (e) => handleOnChange(e, "altContactMobileNumber"), errorMessage: getErrorMsg(invalidFormFields, "altContactMobileNumber") })),
            React.createElement(FtsColumn, { size: { xs: 6 } },
                React.createElement(FtsWrapper, { mb: "2xs" },
                    React.createElement(Span, { variant: VariantsValues["text-3xs"], color: ColorsValue["greyscale-500"] }, "Opt-in")),
                React.createElement(FtsRow, { columnGap: "lg", py: "xs" }, mobileOptIn.map((name, index) => (React.createElement(FtsColumn, { size: { xs: 4 }, key: name },
                    React.createElement(Checkbox, { label: checkboxOptLabels[index], checked: !!altContactData[name], onClick: () => handleCheckboxChange(name, !!altContactData[name], "mobilePhone") })))))),
            React.createElement(FtsColumn, { size: { xs: 6 } },
                React.createElement(Input, { label: "Email", size: InputSizes.sm, name: "altContactEmail", variant: InputVariants.outlined, value: altContactData.altContactEmail, onChange: (e) => handleOnChange(e, "altContactEmail"), errorMessage: getErrorMsg(invalidFormFields, "altContactEmail") })),
            React.createElement(FtsColumn, { size: { xs: 6 } },
                React.createElement(FtsWrapper, { mb: "2xs" },
                    React.createElement(Span, { variant: VariantsValues["text-3xs"], color: ColorsValue["greyscale-500"] }, "Opt-in")),
                React.createElement(FtsRow, { columnGap: "lg", py: "xs" }, primaryOptIn.map((name, index) => (React.createElement(FtsColumn, { size: { xs: 4 }, key: name },
                    React.createElement(Checkbox, { label: checkboxOptLabels[index], checked: !!altContactData[name], onClick: () => handleCheckboxChange(name, !!altContactData[name], "email") })))))),
            React.createElement(FtsColumn, { size: { xs: 12 } },
                React.createElement(RichTextEditor, { value: altContactData.note, onChange: (e) => handleOnChange({ target: { value: e } }, "note"), height: "120px", label: "Note" })),
            React.createElement(FtsColumn, { size: { xs: 8 } }, isEdit && (React.createElement(Button, { onClick: onRemoveAltContact, size: ButtonSizes.sm, type: ButtonTypes.text, color: ButtonColorsValue.accent, iconName: IconNames.Trash, label: "Remove" }))),
            React.createElement(FtsColumn, { size: { xs: 4 } },
                React.createElement(FtsWrapper, { display: "flex", align: "center", justify: "flex-end" },
                    React.createElement(Button, { onClick: closeAltContactForm, size: ButtonSizes.sm, type: ButtonTypes.text, color: ButtonColorsValue.accent, mr: "lg", label: "Cancel" }),
                    React.createElement(Button, { onClick: onSubmit, size: ButtonSizes.sm, type: ButtonTypes.contained, color: ButtonColorsValue.accent, label: submitButtonTitle }))))));
};
