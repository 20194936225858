import React from "react";
import FtsIcons from "@find-truck-service/ui/src/FtsIcons/FtsIcons";
import { FtsButton } from "@find-truck-service/ui/src/FtsButton/FtsButton";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
export const Button = (props) => {
    const { iconName, children, ...rest } = props;
    return (React.createElement(FtsButton, { ...rest },
        !!iconName && React.createElement(FtsIcons, { iconName: props.loading ? IconNames.Loader : iconName }),
        !!props.label && props.loading ? "Loading" : props.label,
        children));
};
