import { activeButtonStyle, disabledButtonStyle, focusButtonStyle, gapStyles, hoverButtonStyle, idleButtonStyle, initialButtonStyles, loadingSvgAnimation, sizingStyles, spacingStylesOnlyIcon, spacingStylesWithLabel, } from "./constants/styles";
import styled from "styled-components";
import { defaultProps, defaultStyleProps } from "../default";
import { ButtonDirections } from "@find-truck-service/types/ui/button";
const buttonProps = ["size", "color", "type", "loading", "label", "direction"];
const buttonAndDefaultProps = [...buttonProps, ...defaultProps];
const buttonStyle = (props) => {
    const { size, color, type, loading, label, direction = ButtonDirections.row } = props;
    return `
    ${initialButtonStyles}
    ${sizingStyles[size]}
    ${gapStyles[size]}
    ${spacingStylesOnlyIcon[size]}
    ${label ? spacingStylesWithLabel[size][direction] : ""}
    ${idleButtonStyle(color, type)}
    &:focus, &:focus-visible { ${focusButtonStyle(color, type)} }
    &:hover { ${hoverButtonStyle(color, type)} };
    &:active { ${activeButtonStyle(color, type)} };
    &:disabled { ${disabledButtonStyle(color, type)} };
    ${loading && disabledButtonStyle(color, type)}
    ${loading && loadingSvgAnimation}
  `;
};
export const FtsButton = styled.button.withConfig({
    shouldForwardProp: (prop) => !buttonAndDefaultProps.includes(prop),
}) `
  ${buttonStyle};
  ${defaultStyleProps};
`;
