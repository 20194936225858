import React, { useCallback } from "react";
import {
  ButtonColorsValue,
  ButtonSizes,
  ButtonTypes,
} from "../dist/types/ui/button";
import { useDispatch, useSelector } from "react-redux";
import { closeSideOutAction } from "../../../redux/actions/v2/sideOut";
import { FtsColumn } from "../dist/ui/src/FtsColumn/FtsColumn";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import { selectAccountData } from "../../../redux/reducers/v2/account";
import { FtsSideOutWrapperFooter } from "../dist/ui/src/FtsSideOut/FtsSideOutWrapperFooter";
import {
  setAccountInvalidFormFieldsAction,
  updateAccountDataByIdAction,
} from "../../../redux/actions/v2/account";
import { accountRowValidator } from "../EditAccountForm/Validators/accountRowValidator";
import { accountCompanyRowValidator } from "../EditAccountForm/Validators/accountCompanyRowValidator";
import { accountSocialLinksRowValidator } from "../EditAccountForm/Validators/accountSocialLinksRowValidator";
import { accountContactRowValidator } from "../EditAccountForm/Validators/accountContactRowValidator";
import { selectAltContacts } from "../../../redux/reducers/v2/altContacts";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";

export const SideOutEditAccountFooter = () => {
  const dispatch = useDispatch();
  const accountData = useSelector(selectAccountData);
  const altContacts = useSelector(selectAltContacts);

  const hideSidebar = useCallback(() => {
    dispatch(closeSideOutAction());
  }, [closeSideOutAction]);

  const updateListing = useCallback(async () => {
    try {
      await accountRowValidator.validate(accountData);
      await accountCompanyRowValidator.validate(accountData);
      await accountSocialLinksRowValidator.validate(accountData);
      await accountContactRowValidator.validate(accountData);
      dispatch(setAccountInvalidFormFieldsAction(null));
      dispatch(updateAccountDataByIdAction(accountData));
    } catch (e) {
      if (e.name !== "ValidationError") return;
      dispatch(setAccountInvalidFormFieldsAction({ [e.path]: e.errors[0] }));
    }
  }, [accountData, altContacts]);

  return (
    <FtsSideOutWrapperFooter py={"lg"} px={"3xl"}>
      <FtsColumn size={{ xs: 8 }} />
      <FtsColumn size={{ xs: 4 }} textAlign={"end"}>
        <FtsWrapper display={"flex"} align={"center"} justify={"flex-end"}>
          <Button
            onClick={hideSidebar}
            type={ButtonTypes.text}
            color={ButtonColorsValue.accent}
            size={ButtonSizes.sm}
            label={"Cancel"}
            mr={"lg"}
          />
          <Button
            onClick={updateListing}
            type={ButtonTypes.contained}
            color={ButtonColorsValue.accent}
            size={ButtonSizes.sm}
            label={"Update account"}
          />
        </FtsWrapper>
      </FtsColumn>
    </FtsSideOutWrapperFooter>
  );
};
