import React, { useCallback, useEffect, useRef, useState } from "react";
import FtsIcons from "../../../FtsIcons/FtsIcons";
import { FtsInput } from "../../../FtsInput/FtsInput";
import { FtsWrapper } from "../../../FtsWrapper/FtsWrapper";
import { TabIndexes } from "@find-truck-service/types/constants/tabIndexes";
import { InputLabel } from "@find-truck-service/ui/src/FtsInput/InputLabel";
import { AppendIcon } from "@find-truck-service/ui/src/FtsInput/AppendIcon";
import { PrependIcon } from "@find-truck-service/ui/src/FtsInput/PrependIcon";
import { InputHelperText } from "@find-truck-service/ui/src/FtsInput/InputHelperText";
export const Input = (props) => {
    const { errorMessage, onBlur, size, required, appendIcon, prependIcon, helperText, onAppendIconClick, invalid, label, variant, disabled, placeholder, ...defaultProps } = props;
    const ref = useRef();
    const showLabel = !!label || required;
    const [errorMessageInternal, setErrorMessageInternal] = useState("");
    const isInvalid = !!errorMessageInternal || !!errorMessage || !!invalid;
    const helperTextValue = errorMessageInternal || errorMessage || helperText;
    const handleKeyDown = useCallback((event) => {
        if (event.key === "Enter" || event.key === " ") {
            event.preventDefault();
            onAppendIconClick && onAppendIconClick();
        }
    }, []);
    const onBlurInternal = useCallback((event) => {
        if (event && event.target && event.target.value && event.target.value.length >= 1024)
            return setErrorMessageInternal("Input cannot be more than 1024 characters");
        setErrorMessageInternal("");
        onBlur && onBlur();
    }, []);
    useEffect(() => {
        if ((errorMessageInternal || errorMessage) && ref.current)
            ref.current.scrollIntoView({ block: "center", behavior: "auto" });
    }, [errorMessage, errorMessageInternal]);
    return (React.createElement(FtsWrapper, { display: "flex", gap: "2xs", direction: "column", ...defaultProps },
        showLabel && (React.createElement(FtsWrapper, { display: "flex", align: "center", gap: "3xs" },
            React.createElement(InputLabel, { size: size, disabled: disabled }, label),
            required && (React.createElement(InputLabel, { size: size, disabled: disabled }, "*")))),
        React.createElement(FtsWrapper, { position: "relative", align: "center", display: "flex", flex: 1 },
            !!prependIcon && (React.createElement(PrependIcon, { size: size, variant: variant, disabled: disabled },
                React.createElement(FtsIcons, { iconName: prependIcon }))),
            React.createElement(FtsInput, { ref: ref, size: size, variant: variant, ...defaultProps, disabled: disabled, invalid: isInvalid, onBlur: onBlurInternal, placeholder: placeholder, hasAppendIcon: !!appendIcon, hasPrependIcon: !!prependIcon }),
            appendIcon && (React.createElement(AppendIcon, { size: size, variant: variant, disabled: disabled, onKeyDown: handleKeyDown, onClick: onAppendIconClick, tabIndex: disabled ? TabIndexes.INACTIVE : TabIndexes.ACTIVE },
                React.createElement(FtsIcons, { iconName: appendIcon })))),
        !!helperTextValue && (React.createElement(InputHelperText, { size: size, disabled: disabled, dangerouslySetInnerHTML: { __html: helperTextValue }, errorMessage: !!errorMessageInternal || !!errorMessage || !!invalid }))));
};
