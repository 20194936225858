import styled from "styled-components";
import { Spacing } from "@find-truck-service/types/ui/spacing";
import { TextStyle } from "@find-truck-service/ui/src/FtsText";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { LinkSizes } from "@find-truck-service/types/ui/link";
import { defaultProps, defaultStyleProps } from "@find-truck-service/ui/src/default";
import { LineHeightVariantsValues, VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
const linkProps = ["label", "iconName", "size"];
const linkAndDefaultProps = [...linkProps, ...defaultProps];
export const linkStyle = `
  cursor: pointer;
  width: fit-content;
  align-items: center;
  text-decoration: none;
  border-radius: ${Spacing["0"]};
  color: ${ColorsValue["blue-accent-500"]};

  &:visited {
    text-decoration: none;
    color: ${ColorsValue["purple-500"]};
  }

  &:focus-visible {
    text-decoration: underline;
    border-radius: ${Spacing["2xs"]};
    color: ${ColorsValue["blue-accent-500"]};
    outline: ${Spacing["3xs"]} solid ${ColorsValue["blue-accent-300"]};
  };

  &:hover {
    outline: none;
    text-decoration: underline;
    color: ${ColorsValue["blue-accent-600"]};
  }

  &:active {
    text-decoration: underline;
    border-radius: ${Spacing["2xs"]};
    color: ${ColorsValue["greyscale-800"]};
    outline: ${Spacing["4xs"]} solid ${ColorsValue["blue-accent-300"]};
  }
`;
const gapPerSize = {
    [LinkSizes.sm]: Spacing["2xs"],
    [LinkSizes.md]: Spacing["xs"],
    [LinkSizes.lg]: Spacing["sm"],
};
const iconSize = {
    [LinkSizes.sm]: Spacing["lg"],
    [LinkSizes.md]: Spacing["xl"],
    [LinkSizes.lg]: Spacing["2xl"],
};
const variantFontSize = {
    [LinkSizes.sm]: VariantsValues["text-4xs"],
    [LinkSizes.md]: VariantsValues["text-3xs"],
    [LinkSizes.lg]: VariantsValues["text-2xs"],
};
const spacingLinkStyle = ({ size }) => `
  gap: ${gapPerSize[size]};
`;
const fontLinkStyle = ({ size }) => `
  display: flex;
  ${TextStyle({
    weight: WeightSize.medium,
    variant: variantFontSize[size],
    lineHeightVariant: LineHeightVariantsValues.relaxed,
})};
`;
const iconLinkStyle = ({ size }) => `
  svg { width: ${iconSize[size]}; height: ${iconSize[size]}; }
  path { stroke: ${ColorsValue["blue-accent-500"]};}
  &:visited path { stroke: ${ColorsValue["purple-500"]}; }
  &:focus-visible path { stroke: ${ColorsValue["blue-accent-500"]};}
  &:hover path { stroke: ${ColorsValue["blue-accent-600"]}; }
  &:active path { stroke: ${ColorsValue["greyscale-800"]};}
`;
const disabledLinkStyle = `
  pointer: default;
  pointer-events: none;
  text-decoration: none;
  color: ${ColorsValue["greyscale-300"]};
  path { stroke: ${ColorsValue["greyscale-300"]}; }
`;
export const FtsLink = styled.a.withConfig({
    shouldForwardProp: (prop) => !linkAndDefaultProps.includes(prop),
}) `
  ${fontLinkStyle};
  ${linkStyle};
  ${iconLinkStyle};
  ${spacingLinkStyle};
  ${defaultStyleProps};
  ${({ disabled }) => (disabled ? disabledLinkStyle : "")}
`;
