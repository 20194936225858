import React from "react";
import { FtsSideOutWrapper } from "../../../FtsSideOut/FtsSideOutWrapper";
import { FtsSideOutContainer } from "../../../FtsSideOut/FtsSideOutContainer";
import { ZIndexValues } from "@find-truck-service/types/constants/zIndexValues";
const SideOut = (props) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(FtsSideOutWrapper, { isOpen: props.isOpen, onClick: props.closeSideOut, ...props.sideOutWrapperProps, zIndex: props.isInFocus ? ZIndexValues.SIDE_OUT_WRAPPER_FOCUSSED : ZIndexValues.SIDE_OUT_WRAPPER_UN_FOCUSSED }),
        React.createElement(FtsSideOutContainer, { boxShadow: "md", isOpen: props.isOpen, ...props.sideOutContainerProps, zIndex: props.isInFocus ? ZIndexValues.SIDE_OUT_CONTAINER_FOCUSSED : ZIndexValues.SIDE_OUT_CONTAINER_UN_FOCUSSED }, props.children)));
};
export default SideOut;
